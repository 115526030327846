import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Karun = ({ anomalyData}) => {
  const chartContainerRef = useRef(null);

    //  console.log('typeof',anomalyData)


  let data = [];

  for (let i in anomalyData) {
    let dataG =
    {
      date: Math.floor((new Date(anomalyData[i].paramDatetime) / 1000) * 1000),
      //  date: new Date(Date.parse(anomalyData[i].paramDatetime)),
      // value1: anomalyData[i].meanVal,
      paramValue: anomalyData[i].paramValue,
      alert: anomalyData[i].alert,
      warning: anomalyData[i].warning,
      // paramcode: anomalyData[i].paramCode,
      // paramUnit: anomalyData[i].paramUnit,
        paramDatetime: Math.floor((new Date(anomalyData[i].paramDatetime) / 1000) * 1000),
      // paramDatetime1: formatDateToTime(anomalyData[i].paramDatetime)
    }
    data.push(dataG)
  }
  data.sort((x,y) => x.paramDatetime - y.paramDatetime)
  // console.log("deata",data)
  

  useEffect(() => {
    let root = am5.Root.new(chartContainerRef.current);

    root.setThemes([am5themes_Animated.new(root)]);

    // root.dateFormatter.setAll({
    //   dateFormat: "yyyy-MM-dd hh:mm:sss",
    //   dateFields: ["date"],
    // })

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      focusable: true,
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        // maxDeviation: 0.1,
        baseInterval: {
          timeUnit: "minute",
          count: 5,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 60,
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root,{}),
        // tooltipDateFormat: "dd MM yyyy",
      })
    );

    
// xAxis.children.push(am5.Label.new(root, { text: "hours", x: am5.p50, centerX: am5.p50 }));

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 5,
        min: 0,
        max: 20,
        strictMinMax: false,
        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom" }),
      })
    );
  
// yAxis.children.moveValue(am5.Label.new(root, { text: data.meanVal, rotation: -90, y: am5.p50, centerX: am5.p50 }), 0);

let series = chart.series.push(am5xy.LineSeries.new(root, {
  // name: "Mean",
  minBulletDistance: 10,
  connect: false,
  xAxis: xAxis,
  yAxis: yAxis,
  valueYField: "paramValue",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {}),
}));
series.get("tooltip").label.set("text", "{valueY}")
series.data.setAll(data);
//  series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
// series.get("tooltip").get("background").set("fillOpacity", 0.5);
series.strokes.template.setAll({
  strokeWidth: 2
});

let series2 = chart.series.push(am5xy.LineSeries.new(root, {
  name: "Alert",
  xAxis: xAxis,
  yAxis: yAxis,
  valueYField: "alert",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {
    labelText: "{name}:{valueY}"
  }),
  stroke: am5.color("#FF0C0C "), // สีแดง
  fill: am5.color("#FF0C0C "),   // สีแดง
}));
series2.strokes.template.setAll({
  strokeDasharray: [8, 8],
  stroke: am5.color("#FF0C0C "), // สีแดง
  strokeWidth: 2,
});
//
let series3 = chart.series.push(am5xy.LineSeries.new(root, {
  xAxis: xAxis,
  name: "Warning",
  yAxis: yAxis,
  valueYField: "warning",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {
    labelText: "{name}:{valueY}",
  }),
  stroke: am5.color("#F78531  "), // สีแดง
  fill: am5.color("#F78531  "),   // สีแดง
}));
series3.strokes.template.setAll({
  strokeDasharray: [8, 8],
  stroke: am5.color("#F78531  "), // สีแดง
  strokeWidth: 2
});

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis
    }));
    cursor.lineY.set("visible", false);

    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    let rangeDataItem = yAxis.makeDataItem({
      value: 0,
      endValue: anomalyData[0].warning,
    });
    let rangeDataItem2 = yAxis.makeDataItem({
      value: anomalyData[0].warning,
      endValue: anomalyData[0].alert,
    });
    let rangeDataItem3 = yAxis.makeDataItem({
      value: anomalyData[0].alert,
      endValue: 100,
    });

    let yellow = "rgba(255,207,59,1)";
    let lightYellow = "rgba(255,207,59,0.2)";
    let red = "rgba(255, 56, 56, 1)";
    let lightRed = "rgba(255, 56, 56, 0.2)";
    let green = "rgba(106, 185, 111, 1)";
    let lightGreen = "rgba(106, 185, 111, 0.2)";

    let range = series.createAxisRange(rangeDataItem);
    let range2 = series.createAxisRange(rangeDataItem2);
    let range3 = series.createAxisRange(rangeDataItem3);

    // series.fills.template.setAll({
    //   fillOpacity: 0,
    //   visible: true,
    // });
    // series.strokes.template.setAll({
    //   strokeWidth: 2,
    // });

    range.strokes.template.setAll({
      stroke: green,
    });

    range.fills.template.setAll({
      fill: lightGreen,
      fillOpacity: 0.6,
      visible: true,
    });

    range2.strokes.template.setAll({
      stroke: yellow,
    });
    range2.fills.template.setAll({
      fill: lightYellow,
      fillOpacity: 0.6,
      visible: true,
    });
    range3.strokes.template.setAll({
      stroke: red,
    });
    range3.fills.template.setAll({
      fill: lightRed,
      fillOpacity: 0.6,
      visible: true,
    });

      series.set("fill", am5.color(0x50b300));
     series2.set("fill", am5.color("#FE0909"));
     series3.set("fill", am5.color("#EE8C00"));
    // series4.set("fill", am5.color(0xff0000));




     series.data.setAll(data);
    series2.data.setAll(data);
    series3.data.setAll(data);

    let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {

    }));

    
    // legend.itemContainers.template.events.on("pointerover", function(e) {
    //   let itemContainer = e.target;
    
    //   // As series list is data of a legend, dataContext is series
    //   // let series = itemContainer.dataItem.dataContext;
    
    //   // chart.series.each(function(chartSeries) {
    //   //   if (chartSeries != series) {
    //   //     chartSeries.strokes.template.setAll({
    //   //       strokeOpacity: 0.15,
    //   //       stroke: am5.color(0x000000)
    //   //     });
    //   //   } else {
    //   //     chartSeries.strokes.template.setAll({
    //   //       strokeWidth: 3,
    //   //     });
    //   //   }
    //   // })
    // })
    
    // // When legend item container is unhovered, make all series as they are
    // legend.itemContainers.template.events.on("pointerout", function(e) {
  
    //   // chart.series.each(function(chartSeries) {
    //   //   chartSeries.strokes.template.setAll({
    //   //     strokeOpacity: 1,
    //   //     strokeWidth: 1,
    //   //     stroke: chartSeries.get("fill")
    //   //   });
    //   // });
    // })

    legend.data.setAll(chart.series.values);

    // series4.data.setAll(data);
    // series3.data.setAll(anomalyData);

    // let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {

    // }));

    
    // legend.itemContainers.template.events.on("pointerover", function(e) {
    //   let itemContainer = e.target;
    
    //   // As series list is data of a legend, dataContext is series
    //   let series = itemContainer.dataItem.dataContext;
    
    //   chart.series.each(function(chartSeries) {
    //     if (chartSeries != series) {
    //       chartSeries.strokes.template.setAll({
    //         strokeOpacity: 0.15,
    //         stroke: am5.color(0x000000)
    //       });
    //     } else {
    //       chartSeries.strokes.template.setAll({
    //         strokeWidth: 3
    //       });
    //     }
    //   })
    // })
    
    // // When legend item container is unhovered, make all series as they are
    // legend.itemContainers.template.events.on("pointerout", function(e) {
  
    //   chart.series.each(function(chartSeries) {
    //     chartSeries.strokes.template.setAll({
    //       strokeOpacity: 1,
    //       strokeWidth: 1,
    //       stroke: chartSeries.get("fill")
    //     });
    //   });
    // })
    
    
    // // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    // legend.data.setAll(chart.series.values);
    
    // series.bullets.push(function () {
    //   var circle = am5.Circle.new(root, {
    //     radius: 4,
    //     fill: series.get("fill"),
    //     stroke: root.interfaceColors.get("background"),
    //     strokeWidth: 1,
    //     toggleKey: "active",
    //   });

    //   return am5.Bullet.new(root, {
    //     sprite: circle,
    //   });
    // });

    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
    //   behavior: "none"
    // }));
    // cursor.lineY.set("visible", false);
    // series.appear(1000);
    // series2.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [anomalyData]);

  return (
    <div
      ref={chartContainerRef}
      style={{ width: "100%", height: "300px" }}
      id={chartContainerRef}></div>
  );
};

export default Karun;



function formatDateToTime(datetime) {
  let date = new Date(datetime);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  
  // Add leading zeros to minutes and seconds if they are less than 10
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  
  return hours + ':' + minutes + ':' + seconds;
}