import React, {useState, useRef, useEffect} from "react";
import {Parallax} from "react-parallax";
import {Popover, Select, Space} from 'antd';
import {DownOutlined} from '@ant-design/icons'
import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import CaseIssue from "../components/CaseIssue";
import BarChart  from "../components/GraphDashbord";
import BarChartAge  from "../components/GraphIssueAge";
import BarChartCC from "../components/GraphCaseCategory"
import BarChartCaseAge from "../components/GraphCaseAge"
import {connect, useSelector, useDispatch} from "react-redux";
import {
    MainPic,
    PlantBl,
    IconPlantWh,
    BgImage,
    AlertInProgress, //alert yellow
    AlertClosed, // alert green
    AlertOff, // alert grey
    AlertOpen, // alert open
    RuntimeInProgressNormal, //runtime yellow
    RuntimeClosed, //runtime green
    RuntimeOpen, //runtime red
    RuntimeOff, // runtime grey
    ChangeEna,
    ChangeDisa,
    Open,
    InProgressNormal,
    EquipPic,
    casestatusopen,
    casestatusclose,
    New,
    Assigned,
    Acknowledge,
    Pending,
    Clear


} from "../assets/images";
import {
    ServiceGetDefaultPlantByUserId,
    ServiceGetEquipmentByPlant,
} from "../services/master.service";
import moment from "moment";
import {Skeleton} from "antd";
import {ServiceGetPlantOverview, 
    ServiceGetLogUpdateIssueLast,
    ServiceGetRumtime, 
    ServiceGetAlert,
    ServiceEnhGetAllEquip,
    ServiceGetAllAbnormalItems,
    ServiceGetIssureCsase,
} from "../services/machine.learning.service";
import {Link, useNavigate} from "react-router-dom";

import "../assets/css/indexNew.css";
import "../assets/css/style.css";
import "../assets/css/main.css";

const HomeNew = (props) => {
    const [plantOverview, setPlantOverview] = useState();
    const [menuRuntime, setMenuRutime] = useState();
    const [abnormalList, setAbnormalList] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [StateStatus, setStateStatus] = useState(1);
    const [caseData, setCaseData] = useState();
    const dispatch = useDispatch();
    let [equid, setEquid] = useState([])
    const reduxPlantOverview = useSelector(
        (state) => state.machineLearning.plantOverview
    );
    const reduxruntme = useSelector(
        (state) => state.machineLearning.runtime
    );
    const reduxalert = useSelector(
        (state) => state.machineLearning.alert
    );

    const reduxEquip = useSelector(
        (state) => state.machineLearning.equid
      );

      const reduxAllAbnormalItems = useSelector(
        (state) => state.machineLearning.allAbnormalItems
      );

      const reduxMlIssue = useSelector(
        (state) => state.machineLearning.mlissue2
      );

    const [Plantruntime,setPlantRuntime] =useState(null);
    const [AllPant, setAllPlan] = useState(null);
    const [normalPercentage,setnormalPercentage] = useState(null);
    const [warningPercentage, setwarningPercentage] = useState(null);
    const [alertPercentage,setalertPercentage] = useState(null);
    const [offPercentage,setoffPercentage] = useState(null);
    const [off, setoff] = useState(null);
    const [Graphtang, setGraphtang] = useState(null);
    const [datagraphAge, setdatagraphAge] = useState(null);
    const [figEquid, setfigEquid] = useState(999)

    const [totalcasenm, settotalcasenm] = useState(null);
    const [totalcasepd, settotalcasepd]= useState(null);
    const [totalcaseh, settotalcaseh] =  useState(null);
    const [totalcaseus, settotalcaseus] = useState(null);

    const [casenm, setcasenm] = useState(null);
    const [casepd, setcasepd]= useState(null);
    const [caseh, setcaseh] =  useState(null);
    const [caseus, setcaseus] = useState(null);
    const [dataCase, setDataCase] = useState(null);
    const [graphCC, setgraphCC] = useState(null);
    const [graphCA, setgraphCA] = useState(null);







    // useEffect(() => {
    //     dispatch(
    //       ServiceEnhGetAllEquip
    //     );
    //     setEquid(reduxEquip)
    //     console.log("Eqi",reduxEquip)
    //   },[])

  const dateFormat = "YYYY/MM/DD";
  const dataFormatApi = "YYYY-MM-DDTHH:mm:ss";
  let today = new Date();
  today.setDate(today.getDate() -1)


  const [date, setDate] = useState(
    moment().subtract(1, "day").format(dataFormatApi)
  );

    // useEffect(() => {
    //         const fetchData = async () => {
    //           try {
    //             const data = await ServiceGetIssureCsase();
    //             setDataCase(data.data);
    //             // console.log("logdata", data.data);

    //             if (data.data != null)  {

    //                 const priorityCount = {
    //                     null: 0,
    //                     "0": 0,
    //                     "1": 0,
    //                     "2": 0,
    //                     "3": 0,
    //                     "4": 0
    //                 };
                    
    //                 data.data.forEach(issue => {
    //                     const priority = issue.issuePriority;
    //                     if (priority === null) {
    //                         priorityCount.null += 1;
    //                     } else {
    //                         priorityCount[priority] += 1;
    //                     }
    //                 });
    //                 setcasenm(priorityCount["0"])
    //                 setcasepd(priorityCount["1"])
    //                 setcaseh(priorityCount["2"])
    //                 setcaseus(priorityCount.null)
    //                 // console.log(`Null: ${priorityCount.null}`);
    //                 // console.log(`1: ${priorityCount["1"]}`);
    //                 // console.log(`2: ${priorityCount["2"]}`);
    //                 // console.log(`3: ${priorityCount["3"]}`);
    //                 // console.log(`4: ${priorityCount["4"]}`);
    //                 const totalCase = priorityCount["0"] + priorityCount["1"] + priorityCount["2"] + priorityCount.null;
    //                 console.log("Case",totalCase)
    //                 const caseNM = Math.min(Math.round((priorityCount["0"] * 100) / totalCase), 100);
    //                 const casePD = Math.min(Math.round((priorityCount["1"] * 100) / totalCase), 100);
    //                 const caseH = Math.min(Math.round((priorityCount["2"] * 100) / totalCase), 100);
    //                 const caseUN = Math.min(Math.round((priorityCount.null * 100) / totalCase), 100);
                    
    //                 settotalcasenm(caseNM);
    //                 settotalcasepd(casePD);
    //                 settotalcaseh(caseH);
    //                 settotalcaseus(caseUN);

    //                 GetGraphCC(data.data);
    //                 GetGraphCA(data.data);
    //                }       

    //           } catch (error) {
    //             console.error("Error fetching data:", error);
    //           }
    //         };
        
    //         fetchData();
      
    //   },[]);
    
      

    useEffect(() => {
        if (!reduxPlantOverview) {
            (async () => {
                setIsLoading(true);
                await dispatch(ServiceGetPlantOverview());
                await dispatch(ServiceGetRumtime());
                await dispatch(ServiceGetAlert());
                // await dispatch(ServiceEnhGetAllEquip())
                setIsLoading(false);
            })();
        } else {
            setPlantOverview(reduxPlantOverview.dashboardItems);
            setAbnormalList(reduxPlantOverview.abnormalItems);
        }
    }, [reduxPlantOverview, dispatch,reduxruntme]);
    
    // useEffect(() => {
    //     if(!reduxruntme){
    //         (async () => {
    //             setIsLoading(true);
    //             await dispatch(ServiceGetRumtime());
                
    //             setIsLoading(false);
    //         })();
    //     }
     
        
    // }, [reduxruntme]);

    useEffect(() => {
        if (!reduxAllAbnormalItems) {
          (async () => {
            setIsLoading(true);
            await dispatch(ServiceGetAllAbnormalItems());
            setIsLoading(false);
          })();
        } else {
          // console.log("ELSE")
          reduxAllAbnormalItems.abnormalItems.map(el => {
            el.issue_DateTime_print = formatDate(el.issue_DateTime)
            el.remark_print = el.remark? el.remark:""
            el.assigned_To_print=el.assigned_To? el.assigned_To:""
        })
        
        //  console.log("reduxAllAbnormalItems",reduxAllAbnormalItems)
        //   setAbnormalList(reduxAllAbnormalItems.abnormalItems);
        //   GetdatagraphAge(reduxAllAbnormalItems.abnormalItems);

        // //   const issueStatusCount = reduxAllAbnormalItems.abnormalItems.reduce((count, item) => {
        // //     if ([0, 1, 2, 3, 4].includes(item.issue_Status)) {
        // //       if (!count[item.issue_Status]) {
        // //         count[item.issue_Status] = {};
        // //       }
        // //       count[item.issue_Status][item.runTime_Level] = 
        // //         (count[item.issue_Status][item.runTime_Level] || 0) + 1;
        // //     }
        // //     return count;
        // //   }, {});
        // const statusMap = {
        //     0: 'New',
        //     1: 'Assigned',
        //     2: 'Acknowledge',
        //     3: 'Pending'
        //   };

        // const issueStatusCount = {};

        //   reduxAllAbnormalItems.abnormalItems.forEach(item => {
        //     if (![0, 1, 2, 3, 4].includes(item.issue_Status)) return;
        
        //     if (!issueStatusCount[item.issue_Status]) {
        //       issueStatusCount[item.issue_Status] = {
        //         issue_Status: statusMap[item.issue_Status],
        //         runTime_Level_1: 0,
        //         runTime_Level_2: 0,
        //         runTime_Level_3: 0,
        //         // Add more runTime_Levels if necessary
        //       };
        //     }
        
        //     if (item.runTime_Level === 1) {
        //       issueStatusCount[item.issue_Status].runTime_Level_1 += 1;
        //     } else if (item.runTime_Level === 2) {
        //       issueStatusCount[item.issue_Status].runTime_Level_2 += 1;
        //     } else if (item.runTime_Level === 3) {
        //         issueStatusCount[item.issue_Status].runTime_Level_3 += 1;
        //       } else if (item.runTime_Level === 4) {
        //         issueStatusCount[item.issue_Status].runTime_Level_4 += 1;
        //       }
        //     // Add more conditions for additional runTime_Levels
        //   });
        
        //   // Convert the object into an array for AMCharts
        //   const chartData = Object.values(issueStatusCount);

        //   console.log("Graph",chartData);

        //   setGraphtang(chartData)
          
        }
      }, [reduxAllAbnormalItems, dispatch]);

      

    
    useEffect(() => {
        setIsLoading(true);
        (async () => {
           await setPlantRuntime(reduxruntme);
           await setMenuRutime(reduxruntme);
            setIsLoading(false);
        })();
       
        // console.log("reduxruntme",reduxruntme);
    }, [reduxruntme]);
    
    useEffect(() => {
        if(StateStatus == 1){
            (async () => {
                setIsLoading(true);
                setPlantRuntime(reduxruntme);
                setIsLoading(false);
            })();
        }else{
            (async () => {
                setIsLoading(true);
                setPlantRuntime(reduxalert);
                setIsLoading(false);
            })();
        }      
    }, [StateStatus, dispatch]);
    
    // useEffect(() => {
       
    //      if (reduxruntme != null)  {
    //         (async () => {
    //             let totals = reduxruntme.reduce((accumulator, ele) => {
    //                 accumulator.normal += ele.normal;
    //                 accumulator.warning += ele.warning;
    //                 accumulator.alert += ele.alert;
    //                 return accumulator;
    //             }, { normal: 0, warning: 0, alert: 0 });
        
    //             let alert = reduxalert.reduce((accumulator, ele) => {
    //                 accumulator.normal += ele.normal;
    //                 accumulator.warning += ele.warning;
    //                 accumulator.alert += ele.alert;
    //                 return accumulator;
    //             }, { normal: 0, warning: 0, alert: 0 });
        
    //             let combinedTotals = {
    //                 normal: totals.normal + alert.normal,
    //                 warning: totals.warning + alert.warning,
    //                 alert: totals.alert + alert.alert
    //             };
    //             setAllPlan(combinedTotals)
    //             setoff(combinedTotals.normal - combinedTotals.warning - combinedTotals.alert)
    //             console.log("DDD", combinedTotals.normal - combinedTotals.warning - combinedTotals.alert)
                
    
    //             const totalPant = combinedTotals.normal + combinedTotals.warning + combinedTotals.alert;
    //             const normalPercentage = Math.min(Math.round((combinedTotals.normal * 100) / totalPant), 100);
    //             const warningPercentage = Math.min(Math.round((combinedTotals.warning * 100) / totalPant), 100);
    //             const alertPercentage = Math.min(Math.round((combinedTotals.alert * 100) / totalPant), 100);
    
             
    //             const offPercentage = 100 - normalPercentage - warningPercentage - alertPercentage;
    //             // setAllPlan(offPercentage);set
    //             setnormalPercentage(normalPercentage);
    //             setwarningPercentage(warningPercentage);
    //             setalertPercentage(alertPercentage);
    //             setoffPercentage(offPercentage);
    //             console.log("runtime", combinedTotals)
    //             console.log("totalPant", alertPercentage)
    //         })();
    
    //         }        
    // },[])

    const GetdatagraphAge = async (data) => {
        console.log("data",data)
        const today = new Date();
  
  
            const weeklyCounts = {};

            data.forEach(item => {
                const issueDateTime = new Date(item.issue_DateTime);
                const diffInDays = Math.floor((today - issueDateTime) / (1000 * 60 * 60 * 24));
                const weekNumber = Math.floor(diffInDays / 7) + 1;
                const weekKey = `week${weekNumber}`;

                  if (!weeklyCounts[weekKey]) {
                    weeklyCounts[weekKey] = {
                      week: weekKey,
                      runTime_Level_1: 0,
                      runTime_Level_2: 0,
                      // Add more runTime_Levels if necessary
                    };
                  }
              
                if (item.runTime_Level === 1) {
                weeklyCounts[weekKey].runTime_Level_1 += 1;
                } else if (item.runTime_Level === 2) {
                weeklyCounts[weekKey].runTime_Level_2 += 1;
                }
            });

            
            const chartData = Object.values(weeklyCounts);
            setdatagraphAge(chartData)

            console.log("FFFFF",chartData); 
    }

    const GetGraphCC  = (dataCase) => {
        console.log("dataCase",dataCase);
        const categorizedData = dataCase.reduce((acc, curr) => {
            const { issueDetail, issueLevel } = curr;
          
            if (!acc[issueDetail]) {
              acc[issueDetail] = { level1: 0, level2: 0 };
            }
          
            if (issueLevel === 1) {
              acc[issueDetail].level1 += 1;
            } else if (issueLevel === 2) {
              acc[issueDetail].level2 += 1;
            }
            return acc;
          }, {});

          const chartData = Object.keys(categorizedData).map(issueDetail => ({
            issueDetail: issueDetail === "null" ? "None" : issueDetail, // แปลง null เป็น 'None'
            level1: categorizedData[issueDetail].level1,
            level2: categorizedData[issueDetail].level2,
          }));
          setgraphCC(chartData)
          console.log("categorizedData",chartData)
    }

    const GetGraphCA = (dataCase) => {
        const calculateIssueAgeInWeeks = (issueDateTime) => {
            const today = new Date();
            const issueDate = new Date(issueDateTime);
            const diffInMs = today - issueDate;
            const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
            return Math.ceil(diffInDays / 7); // แปลงจำนวนวันเป็นจำนวนสัปดาห์
          };
          
          const categorizedData = dataCase.reduce((acc, curr) => {
            const weekNumber = `week${calculateIssueAgeInWeeks(curr.issue_DateTime)}`;
            const { issueLevel } = curr;
          
            if (!acc[weekNumber]) {
              acc[weekNumber] = { level1: 0, level2: 0 };
            }
          
            if (issueLevel === 1) {
              acc[weekNumber].level1 += 1;
            } else if (issueLevel === 2) {
              acc[weekNumber].level2 += 1;
            }
          
            return acc;
          }, {});

          const chartData = Object.keys(categorizedData).map(week => ({
            week,
            level1: categorizedData[week].level1,
            level2: categorizedData[week].level2,
          }));

          console.log("chartData",chartData);
          setgraphCA(chartData)
          
    }

    const getDataAlert = (value) => {
        if (value === -1) {
            return {image: AlertOff, name: "Off"};
        }
        if (value === 0) {
            return {image: AlertClosed, name: "Normal"};
        }

        if (value === 1) {
            return {image: AlertInProgress, name: "Warning"};
        }
        if (value === 2) {
            return {image: AlertOpen, name: "Alert"};
        }
    };
    const getDataRuntime = (value) => {
        if (value === -1) {
            return {image: RuntimeOff, name: "Off"};
        }
        if (value === 0) {
            return {image: RuntimeClosed, name: "Normal"};
        }

        if (value === 1) {
            return {image: RuntimeInProgressNormal, name: "Warning"};
        }
        if (value === 2) {
            return {image: RuntimeOpen, name: "Alert"};
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {day: "2-digit", month: "2-digit", year: "numeric"};
        const formattedDate = date.toLocaleDateString("en-GB", options);
        return formattedDate;
    };

    function statusimg(status) {

        switch (status) {
            case 0:
                return New;
            case 1:
                return Assigned;
            case 2:
                return Acknowledge;
            case 3:
                return Pending;
            case 4:
                return Clear;
            default:
                return 'New';
        }
    };

    function statustotext(status) {

        switch (status) {
            case 0:
                return 'New';
            case 1:
                return 'Assigned';
            case 2:
                return 'Acknowledge';
            case 3:
                return 'Pending';
            case 4:
                return 'Clear';
            default:
                return 'New';
        }
    };
    const handleOpenCase = (obj) => {
        setCaseData(obj);
        setVisible(true);
        dispatch(ServiceGetLogUpdateIssueLast(obj.issue_Id));
    };

    const handleChange = (even) => {
        setStateStatus(even)
        // console.log("Even",even)
    }
    
    return (
        <>
            <MenuComponent {...props} />
            <section className="ml_container clearfix">
                <div className="ml_content clearfix">
                    <div className="ml_sentence_l">
                        <h2 className="txt_h2txt">monitoring process</h2>
                        <br/>
                        <h1 className="txt_h1txt">Machine Learning</h1>
                        <br/>
                        Machine learning is a branch of artificial intelligence (AI) and
                        computer science which focuses on the use of data and algorithms.
                    </div>
                    <div className="ml_image_r">
                        <img src={MainPic} className="img_main" alt="Main Pic"/>
                    </div>
                </div>
            </section>
            {/* IPPS */}
            <section className="ml_plant_sect clearfix">
                <div className="ml_ipps ml_plant_row clearfix">
                    <div className="ml_plant_title txt_h4txt">IPPS</div>
                    {/* <div className="ml_plant_group clearfix">
                        {plantOverview ? (
                            plantOverview
                                .filter((e) => e.plantGroup === "IPPs")
                                .map((obj, index) => (
                                    <div className="ml_plant_col clearfix" key={obj.plantCode}>
                                        <Link
                                            to={{
                                                pathname: `/equipment/${obj.plantCode}`,
                                                state: obj,
                                            }}>
                                            {obj.isAbnormal ? (
                                                <div className="ml_plant_abnormal clearfix">
                                                    <div className="ml_plant_count">{obj.icount}</div>
                                                    <img
                                                        src={IconPlantWh}
                                                        className="img_thumbs"
                                                        alt="Icon Plant"
                                                    />
                                                    <div className="plant_abnor_overlay">
                                                        <div className="normal_img">
                                                            <i className="icon-exclamation-outline"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="ml_plant_normal clearfix">
                                                    <div className="ml_level_sect clearfix">
                                                        <div
                                                            className={`ml_chart_nor h${Math.floor(
                                                                ((obj.green / obj.total) * 100)
                                                            )
                                                            } n${obj.green}`}></div>
                                                        <div
                                                            className={`ml_chart_warn h${Math.floor(
                                                                ((obj.yellow / obj.total) * 100)
                                                            )
                                                            } n${obj.yellow}`}></div>
                                                        <div
                                                            className={`ml_chart_alert h${Math.floor(((obj.red / obj.total) * 100))
                                                            } n${obj.red}`}></div>
                                                        <div
                                                            className={`ml_chart_off h${Math.floor(((obj.grey / obj.total) * 100))
                                                            } n${obj.grey}`}></div>


                                                    </div>
                                                </div>
                                            )}
                                        </Link>
                                        <div className="ml_plant_sub clearfix">
                                            <span className="txt_plant_name">{obj.plantCode}</span>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <>
                                <div className="ml_plant_group clearfix">
                                    <div className="ml_plant_col clearfix">
                                        <div className="ml_plant_sub clearfix">
                                            <Skeleton.Input active size="small"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div> */}
                </div>
                <div className="ml_runtime_status">
                    <div className="run_sta_htxt">TYPE OF STATUS</div>
                    <div className="box-type_status">
                        <Popover title="Outliner &lt; 25%">
              <span>
                <i className="icons-color-status normal_sta"/> Normal
              </span>
                        </Popover>
                        <Popover title="Outliner 25% - 50%">
              <span>
                <i className="icons-color-status warning_sta span_waring"/> Warning
              </span>
                        </Popover>
                        <br/>
                        <Popover title="Outliner &gt; 50%">
              <span>
                <i className="icons-color-status alert_sta"/> Alert
              </span>
                        </Popover>
                        <Popover title="No data available">
              <span>
                <i className="icons-color-status off_sta span_off"/> Off
              </span>
                        </Popover>
                    </div>
                </div>
                <div className="ml_runtime_state">
                    <div className="box-status">
            <span className="box-state">
                State :
            </span>
                        <Select className="box-select"
                                defaultValue="Runtime"
                                style={{color: "#ffffff", width: "100px"}}
                                options={[
                                    {
                                        value: '1',
                                        label: 'Runtime',
                                    },
                                    {
                                        value: '2',
                                        label: 'Alert'
                                    }
                                ]}
                                onChange={handleChange}
                        />
                    </div>
                </div>
            </section>
            {
                StateStatus
            }
            <section className="ml_plant_sect clearfix">
                {/* 12SPPs */}
                <div className="ml_12spps ml_plant_row clearfix">
                    <div className="ml_plant_title txt_h4txt">12SPPs</div>
                    <div className="ml_plant_group clearfix">
                        {Plantruntime ? (
                            Plantruntime
                                .filter((e) => e.plantGroup === "12SPPs")
                                .map((obj, index) => (
                                    <div className="ml_plant_col clearfix" key={obj.plantCode}>
                                        <Link
                                            to={{
                                                pathname: `/equipment/${obj.plantCode}`,
                                            }}>
                                            {obj.isAbnormal ? (
                                                <div className="ml_plant_abnormal clearfix">
                                                    <div className="ml_plant_count">{obj.icount}</div>
                                                    <img
                                                        src={IconPlantWh}
                                                        className="img_thumbs"
                                                        alt="Icon Plant"
                                                    />
                                                    <div className="plant_abnor_overlay">
                                                        <div className="normal_img">
                                                            <i className="icon-exclamation-outline"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="ml_plant_normal clearfix">
                                                    <div className="ml_level_sect clearfix">
                                                    { StateStatus == "1" ? 
                                                    (
                                                        <><div
                                                        className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)
                                                        } n${obj.normal}`}></div>
                                                    <div
                                                        className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                                        } n${obj.warning}`}></div>
                                                   
                                                            <div
                                                                className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                                                } n${obj.alert}`}></div>
                                                        </>
                                                        
                                                    ) :(" ")
                                                }
                                                        

                                                        { StateStatus == "2" ?
                                                            (
                                                                <>
                                                                 <div
                                                            className={`ml_chart_nor h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                                            } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                                            <div
                                                            className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                                            } n${obj.warning}`}></div>
                                                       
                                                                <div
                                                                    className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                                                    } n${obj.alert}`}></div>
                                                                </>
                                                            ) :
                                                            (
                                                                <div
                                                                    className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                                                    } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                                                )
                                                        } 
                                                        
                                                    </div>
                                                </div>
                                            )}
                                        </Link>
                                        <div className="ml_plant_sub clearfix">
                                            <span className="txt_plant_name">{obj.plantCode}</span>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <>
                                <div className="ml_plant_group clearfix">
                                    <div className="ml_plant_col clearfix">
                                        <div className="ml_plant_sub clearfix">
                                            <Skeleton.Input active size="small"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
            {" "}
            {/* 12SPPs */}
            <section className="ml_plant_sect clearfix">
                {/* 7SPPs */}
                <div className="ml_7spps ml_plant_row clearfix">
                    <div className="ml_plant_title txt_h4txt">7SPPs</div>
                    <div className="ml_plant_group clearfix">
                        {Plantruntime ? (
                            Plantruntime
                                .filter((e) => e.plantGroup === "7SPPs")
                                .map((obj, index) => (
                                    <div className="ml_plant_col clearfix" key={obj.plantCode}>
                                        <Link
                                            to={{
                                                pathname: `/equipment/${obj.plantCode}`,
                                            }}>
                                            {obj.isAbnormal ? (
                                                <div className="ml_plant_abnormal clearfix">
                                                    <div className="ml_plant_count">{obj.icount}</div>
                                                    <img
                                                        src={IconPlantWh}
                                                        className="img_thumbs"
                                                        alt="Icon Plant"
                                                    />
                                                    <div className="plant_abnor_overlay">
                                                        <div className="normal_img">
                                                            <i className="icon-exclamation-outline"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="ml_plant_normal clearfix">
                                                    <div className="ml_level_sect clearfix">
                                                        { StateStatus == "1" ? 
                                                        ( <>
                                                             <div
                                                            className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)
                                                            } n${obj.normal}`}></div>
                                                        <div
                                                            className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                                            } n${obj.warning}`}></div>
                                                      
                                                                <div
                                                                    className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                                                    } n${obj.alert}`}></div>
                                                        </>

                                                        ):(" ")}
                                                       

                                                          { StateStatus == "2" ?
                                                            (
                                                            <>
                                                             <div
                                                                className={`ml_chart_nor h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                                                } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                                                <div
                                                                className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                                                } n${obj.warning}`}></div>
                                                                <div
                                                                    className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                                                    } n${obj.alert}`}></div>
                                                            </>
                                                                
                                                        
                                                            ) :
                                                            (
                                                                <div
                                                                    className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                                                    } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                                                )
                                                        } 

                                                    </div>
                                                </div>
                                            )}
                                        </Link>
                                        <div className="ml_plant_sub clearfix">
                                            <span className="txt_plant_name">{obj.plantCode}</span>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <>
                                <div className="ml_plant_group clearfix">
                                    <div className="ml_plant_col clearfix">
                                        <div className="ml_plant_sub clearfix">
                                            <Skeleton.Input active size="small"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* BIOMASS */}
                <div className="ml_biomass ml_plant_row clearfix">
                    <div className="ml_plant_title txt_h4txt">BIOMASS</div>
                    <div className="ml_plant_group clearfix">
                        {plantOverview ? (
                            plantOverview
                                .filter((e) => e.plantGroup === "Biomass")
                                .map((obj, index) => (
                                    <div className="ml_plant_col clearfix" key={obj.plantCode}>
                                        <Link
                                            to={{
                                                pathname: `/equipment/${obj.plantCode}`,
                                            }}>
                                            {obj.isAbnormal ? (
                                                <div className="ml_plant_abnormal clearfix">
                                                    <div className="ml_plant_count">{obj.icount}</div>
                                                    <img
                                                        src={IconPlantWh}
                                                        className="img_thumbs"
                                                        alt="Icon Plant"
                                                    />
                                                    <div className="plant_abnor_overlay">
                                                        <div className="normal_img">
                                                            <i className="icon-exclamation-outline"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="ml_plant_normal clearfix">
                                                    <img
                                                        src={PlantBl}
                                                        className="img_thumbs"
                                                        alt="Plant B"
                                                    />
                                                    <div className="plant_nor_overlay">
                                                        <div className="normal_img">
                                                            <i className="icon-checkmark-outline"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Link>
                                        <div className="ml_plant_sub clearfix">
                                            <span className="txt_plant_name">{obj.plantCode}</span>
                                            <br/>
                                            {/* {obj.isAbnormal ? (
                        <span className="txt_plant_status abnor_status">
                          Abnormal
                        </span>
                      ) : (
                        <span className="txt_plant_status nor_status">
                          Normal
                        </span>
                      )} */}
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <>
                                <div className="ml_plant_group clearfix">
                                    <div className="ml_plant_col clearfix">
                                        <div className="ml_plant_sub clearfix">
                                            <Skeleton.Input active size="small"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* BIOMASS */}
            </section>
            
            {/* 7SPPs */}
            {/* Issue Summary */}
          
            {/* Issue Summary */}
            {/* BG */}
            {" "}
            {/* <section className="ml_plant_sect clearfix">
                <div className="ml_dasdord ml_plant_row clearfix">
                        <div className="ml_plant_title txt_h4txt">EQUIPMENT</div>
                            <div className="ml_runtime_status_dasbord">                   
                                <div className="box-type_status">
                                    <span>
                                        <i className="icons-color-status normal_sta"/> Normal
                                    </span>
                                    <span>
                                        <i className="icons-color-status warning_sta span_waring"/> Warning
                                    </span>      
                                    <span>
                                        <i className="icons-color-status alert_sta span_waring"/> Alert
                                    </span>   
                                    <span>
                                        <i className="icons-color-status off_sta span_off_case"/> Off
                                    </span>
                                                
                                </div>
                                </div>
                            <div className="ml_plant_group clearfix">
                                {
                                    
                                    AllPant ? (                 
                                   <>
                                    <div className="ml_plant_normal_dashbord clearfix">
                                            <div className="ml_level_sect_dashbord clearfix">                   
                                            {/* <div className={`ml_chart_nor h${normalPercentage} n${AllPant.normal}`}></div>
                                            <div className={`ml_chart_warn h${warningPercentage} n${AllPant.warning}`} style={divStyle}></div>
                                            <div className={`ml_chart_alert h${alertPercentage} n${AllPant.alert}`}></div>
                                            <div className={`ml_chart_off h${offPercentage} n${9 - AllPant.normal - AllPant.warning - AllPant.alert}`}></div> 

                                            <div className={`ml_chart_nor h${normalPercentage} n${AllPant.normal}`}>
                                            <span className="centered-content">{AllPant.normal}</span>
                                            </div>

                                            <div className={`ml_chart_warn h${warningPercentage} n${AllPant.warning}`}>
                                            <span className="centered-content">{AllPant.warning}</span>
                                            </div>

                                            <div className={`ml_chart_alert h${alertPercentage} n${AllPant.alert}`}>
                                            <span className="centered-content">{AllPant.alert}</span>
                                            </div>
                                            {/* {

                                            
                                                    off > 0 ?
                                                    ( <div className={`ml_chart_off h${offPercentage} n${AllPant.normal - AllPant.warning - AllPant.alert}`}>
                                                        <span className="centered-content">{9 - AllPant.normal - AllPant.warning - AllPant.alert}</span>
                                                        </div>
                                                        ) :
                                                        (
                                                            <> </>
                                                        )
                                            } 
                                            </div>
                                            </div>
                                        
                                             <div className="ml_plant_sub_dashbord clearfix">
                                             <span className="txt_plant_name_dashbord">RUNTIME STATUS</span>
                                         </div>
                                   </>
                                           
                                                  

                                    )
                                    : (
                                        <>
                                      No data
                                    </>
                                    )
                                }
                                <div className="ad_sens_chart_sum_anoly_dashbord clearfix">
                                <span className="ad_hc_title_le_dashbord left_le"> ISSUE CATEGORY  </span>
                                <div className="pla_s_charts_tank_dsahbord clearfix">
                                
                                        {Graphtang?.length > 0 ?(
                                                <BarChart
                                                data={Graphtang}
                                                />
                                            ) :  (
                                            <h1>Please select equipment</h1>
                                        )}
                               
                                    </div>
                                </div>

                        
                                <div className="ad_sens_chart_sum_anoly_dashbord_3 clearfix">
                                    <span className="ad_hc_title_le_dashbord_3 left_le"> ISSUE AGE DISTRIBUTION  </span>
                                    <div className="pla_s_charts_tank_dsahbord_3 clearfix">
                                    
                                            {datagraphAge?.length > 0 ?( 
                                                    <BarChartAge
                                                    data={datagraphAge}
                                                    />
                                                ) :  (
                                                <h1>Please select equipment</h1>
                                            )
                                            } 
                                
                                        </div>
                                    </div>

                        </div>
                    </div>
            </section>
         
            <section className="ml_plant_sect clearfix">
                <div className="ml_dasdord ml_plant_row clearfix">
                        <div className="ml_plant_title txt_h4txt">CASE</div>
                            <div className="ml_runtime_status_dasbord">                   
                                <div className="box-type_status">
                                    <span>
                                        <i className="icons-color-status normal_sta_case"/> Normal
                                    </span>
                                    <span>
                                        <i className="icons-color-status warning_sta span_waring"/> Pending
                                    </span>      
                                    <span>
                                        <i className="icons-color-status alert_sta span_waring"/> High
                                    </span>   
                                    <span>
                                        <i className="icons-color-status off_sta span_off_case"/> Unassinged
                                    </span>
                                                
                                </div>
                                </div>
                            <div className="ml_plant_group clearfix">
                                {
                                    
                                    AllPant ? (                 
                                   <>
                                    <div className="ml_plant_normal_dashbord clearfix">
                                            <div className="ml_level_sect_dashbord clearfix">                   
                                           

                                            <div className={`ml_chart_nor_case h${totalcaseh} n${caseh}`}>
                                            <span className="centered-content">{caseh}</span>
                                            </div>

                                            <div className={`ml_chart_warn h${totalcasenm} n${casenm}`}>
                                            <span className="centered-content">{casenm}</span>
                                            </div>

                                            <div className={`ml_chart_alert h${totalcasepd} n${casepd}`}>
                                            <span className="centered-content">{casepd}</span>
                                            </div>
                                             <div className={`ml_chart_off h${totalcaseus} n${caseus}`}>
                                                        <span className="centered-content">{caseus}</span>
                                                        </div>
                                                           
                                            </div>
                                            </div>
                                        
                                             <div className="ml_plant_sub_dashbord clearfix">
                                             <span className="txt_plant_name_dashbord">ISSUE PRIORIY</span>
                                         </div>
                                   </>
                                           
                                                  

                                    )
                                    : (
                                        <>
                                      No data
                                    </>
                                    )
                                }
                                <div className="ad_sens_chart_sum_anoly_dashbord clearfix">
                                <span className="ad_hc_title_le_dashbord left_le"> CASE CATEGORY  </span>
                                <div className="pla_s_charts_tank_dsahbord clearfix">

                                {graphCC?.length > 0 ?( 
                                                    <BarChartCC
                                                    data={graphCC}
                                                    />
                                                ) :  (
                                                <h1>Please select equipment</h1>
                                            )
                                            } 

                                    </div>
                                </div>

                          
                        
                                <div className="ad_sens_chart_sum_anoly_dashbord_3 clearfix">
                                    <span className="ad_hc_title_le_dashbord_3 left_le"> CASE AGE DISTRIBUTION  </span>
                                    <div className="pla_s_charts_tank_dsahbord_3 clearfix">
                                    
                                    {graphCA?.length > 0 ?( 
                                                    <BarChartCaseAge
                                                    data={graphCA}
                                                    />
                                                ) :  (
                                                <h1>Please select equipment</h1>
                                            )
                                            } 
                                
                                        </div>
                                    </div>

                        </div>
                    </div>
            </section> */}

            <div
                className="ml_sect_bg ml_bg_fixed"
                style={{backgroundImage: `url(${BgImage})`}}
            />
            {/* BG */}
            <FooterComponent/>
            <CaseIssue
                visible={visible}
                onHide={() => setVisible(false)}
                caseData={caseData}/>

        </>
    );


};

export default HomeNew;
