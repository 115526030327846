import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form, Select, Input, Spin, Skeleton, App } from "antd";
import { connect, useSelector,useDispatch } from "react-redux";
import moment from "moment";

import {
  ServiceGetInitialDataIssued,
  ServicePostUpdateIssued,
  ServiceGetIssure
} from "../services/machine.learning.service.js";
import { ServiceGetLogUpdateIssue} from "../services/master.service.js";
import "../assets/css/style.css";
import "../assets/css/modal.min.css";
import "../assets/css/custom-antdesign.css";

function CaseIssue(props) {
  const tmpUserEditingRoles = props.machineLearning.userEditingRoles;
  const [isLoading, setIsLoading] = useState();
  const [dataIssued, setDataIssued] = useState();
  const [allowSaveIssued, setAllowSave] = useState(false);
  const [formEditMachine] = Form.useForm();

const dispatch = useDispatch();

const dateFormat = "YYYY/MM/DD";
const dataFormatApi = "YYYY-MM-DDTHH:mm:ss";
let today = new Date();
today.setDate(today.getDate() -1)


const [date, setDate] = useState(
  moment().subtract(1, "day").format(dataFormatApi)
);
  const [loadingSave, setLoadingSave] = useState(false);
  const reduxDataIssued = useSelector(
    (state) => state.machineLearning.dataIssued
  );
  const reduxLogUpdateIssue = useSelector(
    (state) => state.master.logUpdateIssue
  );
  const reduxLogUpdateIssuLast = useSelector(
    (state) => state.machineLearning.logUpdateIssuelast
  );

  let reduxissuesummary = useSelector(
    (state) => state.machineLearning.IssueSummary
  );


  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    checkAllowSave();
  }, [tmpUserEditingRoles, props.caseData]);

  useEffect(() => {
    // console.log("reduxLogUpdateIssuLast",reduxLogUpdateIssuLast)
    if (props.visible) {
      formEditMachine.resetFields();
      formEditMachine.setFieldsValue({
        issue_Status: reduxLogUpdateIssuLast?.issueStatus ?? props.caseData.issue_Status,
        // issue_Prioritys: reduxLogUpdateIssuLast?.issuePriority ?? props.caseData.issue_Prioritys,
        Detail: reduxLogUpdateIssuLast?.issueDetail ?? props.caseData.issue_Detail,
        Assigned_To: reduxLogUpdateIssuLast?.assignedTo,
        remark: reduxLogUpdateIssuLast?.remark
      });
    }
  }, [reduxLogUpdateIssuLast, props.visible, formEditMachine]);

  useEffect(() => {
    setDataIssued(reduxDataIssued);
    // console.log("reduxDataIssued",reduxDataIssued);
    setIsLoading(false);
  }, [reduxDataIssued]);

  useEffect(() => {
    // console.log("props",props.caseData.plantCode)
    if (props.caseData) {
      setIsLoading(true);
      props.GetLogUpdateIssue(props.caseData.issue_Id);
      props.GetInitialDataIssued(props.caseData.plant_Code)
    }
  }, [props.visible]);

  const onFinish = async (values, key) => {
    let data = {
      issue_Id: props.caseData.issue_Id,
      assigned_To: values.Assigned_To,
      assigned_By: "",
      issue_Status: values.issue_Status,
      issue_Level: props.caseData.issue_Level,
      issue_Detail: values.Detail,
      remark: values.remark,
      issue_Prioritys: values.issue_Prioritys
    };
    formEditMachine.validateFields().then(

      (async () => {
        setLoadingSave(true);
        // console.log("dataIssue",data)
        await props.PostUpdateIssued(data);
        setTimeout(() => {
          setLoadingSave(false);
          success()
  
        }, 1000);
      })()
    )
    .catch
    ((erro) => {
      
    })
  
    // await ServicePostUpdateIssued(data);
    // onHide();
    //window.location.reload(true);
  };




  const onCancel = () => {
    if (props.onHide != null)
      props.onHide();
  };

  const success = () => {
    Modal.success({
      content: 'SAVE DATA SUCCESS',
      className: 'ant-modal-body-save'
      //  style: { backgroundColor: 'aliceblue'}
    });
    // dispatch(ServiceGetIssure(props.caseData.plant_Code));
    <Link
                to={{
               pathname: `/equipment/${props.caseData.plant_Code}` }} >
    </Link>

    dispatch(
      ServiceGetIssure(
            JSON.stringify({
              plantCode: props.caseData.plant_Code,
              equiP_ID: 1,
              date:date,
            })
        )
    );
  };

  const onerror = () => {
    Modal.error({
      content: 'SAVE DATA Fail',
      className: 'ant-modal-body-save'
      //  style: { backgroundColor: 'aliceblue'}
    });
    // dispatch(ServiceGetIssure(props.caseData.plant_Code));
    <Link
                to={{
               pathname: `/equipment/${props.caseData.plant_Code}` }} >
    </Link>
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }
  function formatTimestamp(timestamp) {
    const [datePart, timePart] = timestamp.split("T");
    const [year, month, day] = datePart.split("-");
    const [time, milliseconds] = timePart.split(".");
    const [hours, minutes, seconds] = time.split(":");

    const formattedDate = `${year}/${month}/${day}`;
    const formattedTime = `${hours}:${minutes}`;

    return [formattedDate, formattedTime];
  }

  function checkAllowSave() {
    if (tmpUserEditingRoles !== null && props.caseData !== null && props.caseData !== undefined) {
      let allowEditing = tmpUserEditingRoles.some((t) => t.allowEditing === true
        && (t.plantCode === props.caseData.plant_Code
          || t.plantCode === 'ALL'
        ));
      setAllowSave(allowEditing);
    }
    else setAllowSave(false);
  }


  return (
    <>

      <Modal
        open={props.visible} footer={null}
        title={null} closable={false}
      >
        <div className="ml-modal-body">
          <button
            value="clear"
            type="reset"
            className="close"
            data-dismiss="modal"
            onClick={(event) => {
              event.preventDefault();
              onCancel();
            }}>
            ×
          </button>
          <div className="modal_title clearfix">Case of issue status</div>
          <div>Please update permission to change an issue status.</div>
          <div className="modal_info clearfix">
            <div className="modal_info_l">
              <div className="modal_info_plant">
                <span className="txt_modal_title">Plant</span>
                <br />
                <span className="txt_modal_sub">
                  {props.caseData ? props.caseData.plant_Code : ""}
                </span>
              </div>
              <div className="modal_info_equip">
                <span className="txt_modal_title">Equipment</span>
                <br />
                <span className="txt_modal_sub">
                  {props.caseData ? props.caseData.equip_Name : ""}
                </span>
              </div>
            </div>
            <div className="modal_info_r">
              <div className="modal_info_equip">
                <span className="txt_modal_title">Date</span>
                <br />
                <span className="txt_modal_sub">
                  {props.caseData ? formatDate(props.caseData.issue_DateTime) : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="modal_formsub clearfix">
            <Skeleton loading={loadingSave} active>

              <Form

                form={formEditMachine}
                onFinish={onFinish}
                >

                <div className="modal_input1 custom-select field_l f_mar20 clearfix">
                  <label>Status </label>
                  <Form.Item

                    name="issue_Status"
                    rules={[{ required: true }]}
                    className="custom-item">
                    <Select id="" name="" >
                      {dataIssued ? (
                        dataIssued.issuedStatus.map((selection) => (
                          //<Option key={selection.mappingCode}>{selection.mappingDescription} {selection.mappingCode==0?"select" : ""}</Option>
                          <Select.Option
                            value={selection.mappingCode}
                            key={selection.mappingCode}>
                            {selection.mappingDescription}
                          </Select.Option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </Form.Item>
                </div>
                <div className="modal_input1 field_r custom-select clearfix">
                  <label>Detail</label>
                  <Form.Item
                    name="Detail"
                    rules={[{ required: true, message: 'Plase select Detail' }]}
                    className="custom-item">
                    <Select id="" name=""  >
                      {dataIssued ? (
                        dataIssued.issuedDetail.map((selection) => (
                          <Select.Option
                            value={selection.mappingCode}
                            key={selection.mappingCode}>
                            {selection.mappingDescription}
                          </Select.Option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </Form.Item>
                </div>
                {/* <div className="modal_input2 custom-select clearfix">
                  <label>Priority</label>
                  <Form.Item
                                       name="issue_Prioritys"
                                       rules={[{ required: true }]}
                                       className="custom-item">
                                       <Select id="" name="" >
                                         {dataIssued ? (
                                           dataIssued.issuePriority.map((selection) => (
                                             //<Option key={selection.mappingCode}>{selection.mappingDescription} {selection.mappingCode==0?"select" : ""}</Option>
                                             <Select.Option
                                               value={selection.mappingCode}
                                               key={selection.mappingCode}>
                                               {selection.mappingDescription}
                                             </Select.Option>
                                           ))
                                         ) : (
                                           <></>
                                         )}
                                       </Select>
                  </Form.Item>
                </div> */}
                <div className="modal_input2 custom-select clearfix">
                  <label>Assigned To</label>
                  <Form.Item
                    name="Assigned_To"
                    rules={[{ required: true, message: 'Plaese select Name' }]}
                    className="custom-item">
                    <Select id="" name="" >
                      {dataIssued ? (
                        dataIssued.assignTo.map((selection) => (
                          <Select.Option
                            value={selection.userId}
                            key={selection.userId}>
                            {selection.userName}
                          </Select.Option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </Form.Item>
                </div>
                <div className="modal_input2">
                  <label>Remark</label>
                  <Form.Item name="remark" className="custom-item-remark">
                    <Input.TextArea id="" name="remark" type="text" />
                  </Form.Item>
                </div>
                <div className="modal_submit">
                  <div className="modal_btn">
                    <button
                      value="clear"
                      type="reset"
                      className="modal_btn_cancel"
                      data-dismiss="modal"
                      onClick={(event) => {
                        event.preventDefault();
                        onCancel();
                      }}>
                      Cancel
                    </button>

                    <button disabled={!allowSaveIssued} type="primary"
                      className={allowSaveIssued ? "modal_btn_save" : "modal_btn_disabled"}
                      // onClick={success}
                      >
                      {"Save"}
                    </button>
                  
                  </div>
                </div>
              </Form>
            </Skeleton>
          </div>
          <div className="modal_lastup" >
            <div className="modal_lastup_title">Last update</div>
            <div className="modal_lastup_cont">
              <div className="modal_lastup_cont_l">Name</div>
              <div className="modal_lastup_cont_r">
                <div className="modal_lastup_date">Date</div>
                <div className="modal_lastup_time">Time</div>
              </div>
            </div>

            <div className="modal_table" >
              {reduxLogUpdateIssue?.length > 0 ? (
                reduxLogUpdateIssue.map((item, index) => {
                  return (
                    <div key={index} className="modal_lastup_list clearfix">
                      <div className="modal_lastup_list_cont list_bor">
                        <div className="modal_lastup_cont_l">{item.updateby}</div>
                        <div className="modal_lastup_cont_r">
                          <div className="modal_lastup_date">
                            {formatTimestamp(item.updatedate)[0]}
                          </div>
                          <div className="modal_lastup_time">
                            {" "}
                            {formatTimestamp(item.updatedate)[1]}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>NO DATA</>
              )}
              <Spin tip="" size="large" spinning={isLoading}></Spin>
            </div>
          </div>
        </div>


      </Modal>
    </>
  );
}


const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  PostUpdateIssued: (props) => {
    console.log("props",props)
    dispatch(ServicePostUpdateIssued(props));
  },
  GetInitialDataIssued: (props) => {
    dispatch(ServiceGetInitialDataIssued(props));
  },
  GetLogUpdateIssue: (props) => {
    dispatch(ServiceGetLogUpdateIssue(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseIssue); 
