export const ActionGetModelOutput = (payload) => ({
  type: "GET_MODEL_OUTPUT",
  payload,
});

export const ActionClearModelOutput = (payload) => ({
  type: "CLEAR_MODEL_OUTPUT",
  payload,
});

export const ActionGetPiData = (payload) => ({
  type: "GET_PI_DATA",
  payload,
});

export const ActionClearPiData = (payload) => ({
  type: "CLEAR_PI_DATA",
  payload,
});

export const ActionGetPlantOverview = (payload) => ({
  type: "GET_PLANT_OVER_VIEW",
  payload,
});

export const ActionClearPlantOverview = (payload) => ({
  type: "CLEAR_PLANT_OVER_VIEW",
  payload,
});

export const ActionGetAllAbnormalItems = (payload) => ({
  type: "GET_ALL_ABNORMAL_ITEMS",
  payload,
});

export const ActionClearAllAbnormalItems = (payload) => ({
  type: "CLEAR_ALL_ABNORMAL_ITEMS",
  payload,
});

export const ActionGetInitialDataIssued = (payload) => ({
  type: "GET_INITIAL_DATA_ISSUED",
  payload,
});

export const ActionClearInitialDataIssued = (payload) => ({
  type: "CLEAR_INITIAL_DATA_ISSUED",
  payload,
});

export const ActionGetEquipmentOverviewByPlantEnhance = (payload) => ({
  type: "GET_EQUIPMENT_OVERVIEW_BY_PLANT_ENHANCE",
  payload,
});
export const ActionEnhGetIssueSummary = (payload) => ({
  type: "GET_EnhGetIssueSummary",
  payload,
});
export const ActionClearEquipmentOverviewByPlantEnhance = (payload) => ({
  type: "CLEAR_EQUIPMENT_OVERVIEW_BY_PLANT_ENHANCE",
  payload,
});

export const ActionClearIssueSummary = (payload) => ({
  type: "CLEAR_EnhGetIssueSummary",
  payload,
});

export const ActionGetHealthScore = (payload) => ({
  type: "GET_HEALTH_SCORE",
  payload,
});

export const ActionClearHealthScore = (payload) => ({
  type: "CLEAR_HEALTH_SCORE",
  payload,
});
export const ActionGetAnomaly = (payload) => ({
  type: "GET_ANOMALY",
  payload,
});

export const ActionClearAnomaly = (payload) => ({
  type: "CLEAR_ANOMALY",
  payload,
});

export const ActionGetPlantStatus = (payload) => ({
  type: "GET_PLANT_STATUS",
  payload,
});
export const ActionClearPlantStatus = (payload) => ({
  type: "CLEAR_PLANT_STATUS",
  payload,
});
export const ActionGetSensor = (payload) => ({
  type: "GET_SENSOR",
  payload,
});
export const ActionGetRanking = (payload) => ({
  type: "GET_RANKING",
  payload,
});
export const ActionClearRanking = (payload) => ({
  type: "CLEAR_RANKING",
  payload,
});
export const ActionGetRuntime = (payload) => ({
  type: "GET_RUNTIME",
  payload,
});
export const ActionClearRuntime = (payload) => ({
  type: "CLEAR_RUNTIME",
  payload,
});
export const ActionGetAlert = (payload) => ({
  type: "GET_ALERT",
  payload,
});
export const ActionClearAlert = (payload) => ({
  type: "CLEAR_ALERT",
  payload,
});
export const ActionGetSensorV2 = (payload) => ({
  type: "GET_SensorV2",
  payload,
});
export const ActionClearSensorV2 = (payload) => ({
  type: "CLEAR_SensorV2",
  payload,
});
export const ActionGetByparam = (payload) => ({
  type: "GET_SensorGraph",
  payload,
});
export const ActionClearByparam = (payload) => ({
  type: "CLEAR_SensorGraph",
  payload,
});
export const ActionGetMLIssue = (payload) => ({
  type: "GET_MLIssue",
  payload,
});
export const ActionGetEquip = (payload) => ({
  type: "GET_Equip",
  payload,
});
export const ActionClearMLIssue = (payload) => ({
  type: "CLEAR_MLIssue",
  payload,
});
export const ActionGetSensorSum = (payload) => ({
  type: "GET_SENSORSUM",
  payload,
});
export const ActionClearSensorSum = (payload) => ({
  type: "CLEAR_SENSORSUM",
  payload,
});
export const ActionClearSensor = (payload) => ({
  type: "CLEAR_SENSOR",
  payload,
});
export const ActionGetLoadType = (payload) => ({
  type: "GET_LOAD_TYPE",
  payload,
});
export const ActionClearLoadType = (payload) => ({
  type: "CLEAR_LOAD_TYPE",
  payload,
});
export const ActionGetDetail = (payload) => ({
  type: "GET_DETAIL",
  payload,
});
export const ActionClearDetail = (payload) => ({
  type: "CLEAR_DETAIL",
  payload,
});

export const ActionStoreSensorImg = (payload) => ({
  type: "STORE_SENSOR_IMG",
  payload,
});
export const ActionClearLogUpdateIssueLast = (payload) => ({
  type: "CLEAR_LOG_UPDATE_ISSUE_Last",
  payload,
});
export const ActionGetLogUpdateIssueLast = (payload) => ({
  type: "Get_LOG_UPDATE_ISSUE_Last",
  payload,
});

export const ActionGetUserEditingRoles = (payload) => ({
  type: "GET_USER_EDITING_ROLE",
  payload,
});
export const ActionClearUserEditingRoles = (payload) => ({
  type: "CLEAR_USER_EDITING_ROLE",
  payload,
});

export const ActionDownloadManualFile = () => ({
  type: "DOWNLOAD_MANUAL_FILE"
});
export const ActionDownloadManualFileSuccess = () => ({
  type: "DOWNLOAD_MANUAL_FILE_SUCCESS"
});


