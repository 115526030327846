import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import CaseIssue from "../components/CaseIssue";
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd';
import moment from "moment";
import {
  MainPic,
  PlantBl,
  IconPlantWh,
  BgImage,
  AlertInProgress, //alert yellow
  AlertClosed, // alert green
  AlertOff, // alert grey
  AlertOpen, // alert open
  RuntimeInProgressNormal, //runtime yellow
  RuntimeClosed, //runtime green
  RuntimeOpen, //runtime red
  RuntimeOff, // runtime grey
  ChangeEna,
  ChangeDisa,
  Open,
  InProgressNormal,
  EquipPic,
  New,
  Assigned,
  Acknowledge,
  Pending,
  Clear,
  New2
} from "../assets/images";

import {
  ServiceGetDefaultPlantByUserId,
  ServiceGetEquipmentByPlant,
} from "../services/master.service";

import {
  ServiceGetPlantOverview,
  ServiceGetAllAbnormalItems, ServiceGetLogUpdateIssueLast
} from "../services/machine.learning.service";

import "../assets/css/indexNew.css";
import "../assets/css/main.css";
import "../assets/css/style.css";


const { RangePicker } = DatePicker;
const IssueSummary = (props) => {
  const [abnormalList, setAbnormalList] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [visible, setVisible] = useState(false);

  const [caseData, setCaseData] = useState(null);

  const dispatch = useDispatch();
  const reduxAllAbnormalItems = useSelector(
    (state) => state.machineLearning.allAbnormalItems
  );
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const getDataAlert = (value) => {
   
    if (value === -1) {
      return { image: AlertOff, name: "Off" };
    }
    if (value === 0) {
      return { image: AlertClosed, name: "Normal" };
    }

    if (value === 1) {
      return { image: AlertInProgress, name: "Warning" };
    }
    if (value === 2) {
      return { image: AlertOpen, name: "Alert" };
    }
  };
  const getDataRuntime = (value) => {
    if (value === -1) {
      return { image: RuntimeOff, name: "Off" };
    }
    if (value === 0) {
      return { image: RuntimeClosed, name: "Normal" };
    }

    if (value === 1) {
      return { image: RuntimeInProgressNormal, name: "Warning" };
    }
    if (value === 2) {
      return { image: RuntimeOpen, name: "Alert" };
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {


    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
   
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchPropsdatepicker = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
         <DatePicker 
         allowClear={false}
         format="DD/MM/YYYY" 
         value={selectedKeys[0]}
         onChange={(value,dateString) => {
         
          setSelectedKeys([moment(value)])
       
          
         }} />
         {/* <RangePicker format="DD/MM/YYYY" onChange={(value,dateString) => {
          setSelectedKeys(value)
         }}
          onOk={onOk} /> */}
        {/* <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() =>  (selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        /> */}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>

    record[dataIndex].toString().toLowerCase().includes(formatDate(value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{
    //         backgroundColor: '#ffc069',
    //         padding: 0,
    //       }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  const columns = [
    {
      title: 'ISSUE ID',
      dataIndex: 'issue_Id',
      key: 'issue_Id',
      width: '5%',
      ...getColumnSearchProps('issue_Id'),
    },
    {
      title:'DATE',
      dataIndex: 'issue_DateTime_print',
      key: 'issue_DateTime_print',
      width: '10%',
      ...getColumnSearchPropsdatepicker('issue_DateTime_print')
    },
    {
      title: 'PLANT',
      dataIndex: 'plant_Code',
      key: 'plant_Code',
      width: '10%',
      ...getColumnSearchProps('plant_Code'),
    },
    {
      title: 'EQUIPMENT',
      dataIndex: 'equip_Name',
      key: 'equip_Name',
      width: '20%',
      ...getColumnSearchProps('equip_Name'),
    },
    {
  
      title:'ALERT STATE',
      dataIndex: 'issue_Level',
      key:  'issue_Level',
      width: '10%',
      filters: [
        {
          text: 'OFF',
          value: '-1',
        },
        {
          text: 'Normal',
          value: '0',
        },
        {
          text: 'Warning',
          value: '1',
        },
        {
          text: 'Alert',
          value: '2',
        },
       
      ],
      onFilter: (value, record) => record['issue_Level'].toString().toLowerCase().includes(value.toLowerCase()),
      render:  (t, r) =>  <div className="tooltip">
      <img src={r.issue_Level ? getDataAlert(r.issue_Level).image :getDataAlert(0).image} />
      <span className="tooltiptext">
        {getDataAlert(r.issue_Level).name}
      </span>
    </div>
      
     
    },
    {
     
      title:'RUNTIME',
      dataIndex: 'runTime_Level',
      key:  'runTime_Level',
      width: '10%',
      filters: [
        {
          text: 'OFF',
          value: '-1',
        },
        {
          text: 'Normal',
          value: '0',
        },
        {
          text: 'Warning',
          value: '1',
        },
        {
          text: 'Alert',
          value: '2',
        },
       
      ],
      onFilter: (value, record) => record['runTime_Level'].toString().toLowerCase().includes(value.toLowerCase()),
      render:  (t, r) =><div className="tooltip">
       <img src={r.runTime_Level ? getDataRuntime(r.runTime_Level).image : getDataRuntime(0).image} />
       <span className="tooltiptext">
        {getDataAlert(r.runTime_Level).name}
      </span>
    </div>
    },
    {
   
      title:'CASE STATUS',
      dataIndex: 'issue_Status',
      key:  'issue_Status',
      width: '5%',
      filters: [
      
        {
          text: 'New',
          value: '0',
        },
        {
          text: 'Assigned',
          value: '1',
        },
        {
          text: 'Acknowledge',
          value: '2',
        },
        {
          text: 'Pending',
          value: '3',
        },
        {
          text: 'Clear',
          value: '4',
        },
       
      ],
      onFilter: (value, record) => record['issue_Status'].toString().toLowerCase().includes(value.toLowerCase()),
      render:  (t, r) =>  <div
      className=" tooltip "
      onClick={() => handleOpenCase(r)}>
      <img src={r.issue_Status? statusimg(r.issue_Status): statusimg(0)} className="btn_a btn_status" alt="" />
      <span className="tooltiptext">{statustotext(r.issue_Status)}</span>
    </div>
    },
    {
      title: 'ASSIGNED TO',
      dataIndex: 'assigned_To_print',
      key: 'assigned_To_print',
      width: '15%',
      ...getColumnSearchProps('assigned_To_print'),
    },
    {
      title: 'REMARK',
      dataIndex: 'remark_print',
      key: 'remark_print',
      width: '25%',
      ...getColumnSearchProps('remark_print'),
    },
    // {
    //   title: 'Address',
    //   dataIndex: 'address',
    //   key: 'address',
    //   ...getColumnSearchProps('address'),
    //   sorter: (a, b) => a.address.length - b.address.length,
    //   sortDirections: ['descend', 'ascend'],
    // },
  ];
  // console.log("AAA",reduxAllAbnormalItems)
  useEffect(() => {
    if (!reduxAllAbnormalItems) {
      (async () => {
        setIsLoading(true);
        await dispatch(ServiceGetAllAbnormalItems());
        setIsLoading(false);
      })();
    } else {
      // console.log("ELSE")
      reduxAllAbnormalItems.abnormalItems.map(el => {
        el.issue_DateTime_print = formatDate(el.issue_DateTime)
        el.remark_print = el.remark? el.remark:""
        el.assigned_To_print=el.assigned_To? el.assigned_To:""
    })
    
    //  console.log("reduxAllAbnormalItems",reduxAllAbnormalItems)
      setAbnormalList(reduxAllAbnormalItems.abnormalItems);
      
      
    }
  }, [reduxAllAbnormalItems, dispatch]);

  

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  function statusimg(status) {

    switch (status) {
      case 0:
        return New2;
      case 1:
        return Assigned;
      case 2:
        return Acknowledge;
      case 3:
        return Pending;
      case 4:
        return Clear;
      default:
        return 'New';
    }
  };
  function statustotext(status) {

    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'Assigned';
      case 2:
        return 'Acknowledge';
      case 3:
        return 'Pending';
      case 4:
        return 'Clear';
      default:
        return 'New';
    }
  };

  function priority(status) {

    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'Assigned';
      case 2:
        return 'Acknowledge';
      case 3:
        return 'Pending';
      case 4:
        return 'Clear';
      default:
        return 'New';
    }
  };

  const handleOpenCase = (obj) => {
    setCaseData(obj);
    setVisible(true);
    dispatch(ServiceGetLogUpdateIssueLast(obj.issue_Id));
  };
  return (
    <>
      <MenuComponent></MenuComponent>
      <section className="eq_container clearfix">
        <div className="eq_top_img" data-parallax='{"x": -180}'>
          <img src={EquipPic} className="img_equip_pic" />
        </div>
        <div className="eq_top_sect clearfix">
          <div className="eq_content clearfix">
            <div className="eq_sentence">
              <div className="eq_sentence_title">
                <h2 className="txt_h2txt">Event Log Data Analysis</h2>
                <br />
                <h1 className="txt_equip_title">Issue Summary</h1>
              </div>
            </div>
          </div>
          <div className="eq_top_bg short clearfix" />
        </div>
      </section>
      <section>
      
      </section>
      {/* Select Categories */}
      {/* Data */}
      <section className="data_container eq_b32 clearfix">
        <div className="data_subject_title clearfix">
          <div className="eq_sentence">
            <h2 className="txt_h3txt_head">progress list</h2>
          </div>
        </div>
        <div className="data_content_issue clearfix">
      
          {/* Table Title */}
          <Table columns={columns} dataSource={abnormalList} className=" clearfix" key="{abnormalList.issue_Id}"  />
        
          {/* Table Title */}
          {/* Table Row */}
        
          <Spin tip="" size="large" spinning={isLoading}>
            <div id="chartdiv" style={{ height: "auto" }}></div>
          </Spin>

          {/* Table Row */}
        </div>
      </section>
      {/* Data */}
      {/* BG */}
      <div
        className="ml_sect_bg ml_bg_fixed"
        style={{ backgroundImage: `url(${BgImage})` }}
      />
      {/* BG */}
      {/*Modal Case Issue */}
      {/* <div
        className="modal fade"
        id="case_issue"
        data-keyboard="false"
        data-backdrop="false">
        <CaseIssue />
      </div> */}
      {/*Modal Case Issue */}
      <FooterComponent></FooterComponent>
      <CaseIssue
        visible={visible}
        onHide={() => setVisible(false)}
        caseData={caseData} />
    </>
  );
};

export default IssueSummary;
