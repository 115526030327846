import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const AnomalyChart = ({ anomalyData, date }) => {
  const chartContainerRef = useRef(null);

  //  console.log("dataGraph",anomalyData)

  let data = anomalyData.map((item) => ({
    date: Math.floor(new Date(item.paramDatetime).getTime() / 1000) * 1000,
    value1: item.predictValue,
    value2: item.paramValue,
    paramcode: item.paramCode,
    paramUnit: item.paramUnit,
    paramDatetime: Math.floor(new Date(item.paramDatetime).getTime() / 1000) * 1000,
  })).sort((x, y) => x.paramDatetime - y.paramDatetime);

  useEffect(() => {
    let root = am5.Root.new(chartContainerRef.current);

    root.setThemes([am5themes_Animated.new(root)]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd hh:mm",
      dateFields: ["valueX"],
    });

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
    }));

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "minute",
          count: 5,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        min: "0",
        max: "paramValue",
        strictMinMax: false,
        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom" }),
      })
    );

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "ParamValue",
        xAxis: xAxis,
        yAxis: yAxis,
        connect: false,
        valueYField: "value2",
        valueXField: "paramDatetime",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}:{valueY}"
        }),
        stroke: am5.color("#716F6F"), // สีเขียว
        fill: am5.color("#716F6F"),   // สีเขียว
      })
    );

    series2.strokes.template.setAll({
      stroke: am5.color("#716F6F"), // สีเขียว
      strokeWidth: 1,
    });

    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "PredictValue",
        xAxis: xAxis,
        yAxis: yAxis,
        connect: false,
        valueYField: "value1",
        valueXField: "paramDatetime",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}:{valueY}"
        }),
        zIndex: 1,
        stroke: am5.color("#00FF00"), // สีแดง
        fill: am5.color("#00FF00"),   // สีแดง
      })
    );

    series1.strokes.template.setAll({
      stroke: am5.color("#00FF00"), // สีแดง
      strokeWidth: 3,
    });

    // series2.set("fill", am5.color("#908E8E"));
    // series1.set("fill", am5.color("#50b300"));

    series2.data.setAll(data);
    series1.data.setAll(data);

    let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {}));

    legend.itemContainers.template.events.on("pointerover", function(e) {
      let itemContainer = e.target;
      let series = itemContainer.dataItem.dataContext;
      chart.series.each(function(chartSeries) {
        if (chartSeries != series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 4,
          });
        }
      })
    });

    legend.itemContainers.template.events.on("pointerout", function(e) {
      chart.series.each(function(chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 3,
          stroke: chartSeries.get("fill")
        });
      });
    });

    legend.data.setAll(chart.series.values);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [anomalyData, date]);

  return (
    <div
      ref={chartContainerRef}
      style={{ width: "100%", height: "300px" }}
      id={chartContainerRef}
    ></div>
  );
};

export default AnomalyChart;
