import React, { useState, useEffect } from "react";
import { Skeleton, Tooltip, Spin } from "antd";
import { useMsal } from "@azure/msal-react";

import {
  IconHome, IconHomeGreen, IconAlert,
  IconAlertGreen, IconPlant, IconPlantBlGreen,
  IconPlantWh, IconLogout, IconGulfGreen,
  IconUser, IconHistory, IconHistoryGreen,
  IconDownload, IconDownloadGreen,
} from "../assets/images";
import { connect } from "react-redux";

import { ServiceGetPlantOverview, ServiceDownloadManualFile, ServiceGetRumtime 
} from "../services/machine.learning.service";
import { ServiceGetUserNotification } from "../services/notification.service";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import "../assets/css/indexNew.css";
import "../assets/css/style.css";
const Menu = (props) => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [openPlant, setOpentPlant] = useState(false);
  const [openCity, setOpenCity] = useState("ipps");
  const [plantOverview, setPlantOverview] = useState();
  const [notifi, setNotifi] = useState();
  const [openNotifi, setOpentNotifi] = useState(false);
  const name = accounts[0] && accounts[0].name;
  const { instance } = useMsal();

  useEffect(() => {
   
    // (async () => {
    //   const runtimeData = await props.ServiceGetRumtime();
    //   if (props.machineLearning.plantOverview) {
    //     console.log("props.machineLearning.plantOverview", runtimeData);
    //     setPlantOverview(props.machineLearning.plantOverview.dashboardItems);
    //   } else {
    //     setPlantOverview(runtimeData);
    //   }
    // })();

    // setPlantOverview()
    if (props.machineLearning.runtime) {
      //  console.log("props.machineLearning.plantOverview",props.machineLearning.runtime)
      setPlantOverview(props.machineLearning.runtime);
    } else {
      (async () => {
        await ServiceGetRumtime();
      })();
    }
  }, [props.machineLearning.runtime]);
  // }, [props.machineLearning.plantOverview]);

  useEffect(() => {
    if (props.notification.notifi) {
      setNotifi(props.notification.notifi);
    } else {
      (async () => {
        await props.ServiceGetUserNotification();
      })();
    }
  }, [props.notification.notifi]);

  const handleLogout = (instance) => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const ComponnentMenuPlant = () => {
    const getEquipmentByPlant = async (plant) => {
      navigate(`/equipment/${plant}`);
    };
    return (
      <div
        id="myDropdown"
        className={openPlant ? "dropdown-content show" : "dropdown-content"}
        onMouseLeave={(e) => {
          setOpentNotifi(false);
          setOpentPlant(false);
        }}>
        <div className="tab">
          <button
            className={openCity === "biomass" ? "tablinks active" : "tablinks"}
            onClick={() => setOpenCity("biomass")}>
            Biomass
          </button>
          <button
            className={openCity === "7spps" ? "tablinks active" : "tablinks"}
            onClick={() => setOpenCity("7spps")}>
            7 SPPs
          </button>
          <button
            className={openCity === "12spps" ? "tablinks active" : "tablinks"}
            onClick={() => setOpenCity("12spps")}>
            12 SPPs
          </button>
          <button
            className={openCity === "ipps" ? "tablinks active" : "tablinks"}
            onClick={() => setOpenCity("ipps")}>
            IPPs
          </button>
        </div>

        <div
          id="biomass"
          className={
            openCity === "biomass"
              ? "tabcontent show w3-animate-opacity"
              : "tabcontent w3-animate-opacity"
          }>
          <div className="ml_biomass_nav ml_plant_row_nav clearfix">
            <div className="ml_plant_group clearfix">
              {plantOverview ? (
                plantOverview
                  .filter((e) => e.plantGroup === "Biomass")
                  .map((obj, index) => (
                    <div
                      className="ml_plant_col_nav clearfix"
                      key={obj.plantCode}>
                      <a onClick={() => getEquipmentByPlant(obj.plantCode)}>
                        {obj.isAbnormal ? (
                          <div className="ml_plant_abnormal clearfix">
                            <div className="ml_plant_count">{obj.icount}</div>
                            <img src={IconPlantWh} className="img_thumbs" />
                            <div className="plant_abnor_overlay">
                              <div className="normal_img">
                                <i className="icon-exclamation-outline"></i>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="ml_plant_normal clearfix">
                                                    <div className="ml_level_sect clearfix">
                                                        <div
                                                            className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)
                                                            } n${obj.normal}`}></div>
                                                        <div
                                                            className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                                            } n${obj.warning}`}></div>
                                                        {/* { StateStatus == "2" ?
                                                            ( */}
                                                                <div
                                                                    className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                                                    } n${obj.alert}`}></div>
                                                            {/* ) :
                                                            ( <div> </div>)
                                                        } */}

                                                        
                                                                <div
                                                                    className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                                                    } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                                                
                                                        
                                                        
                                                    </div>
                                                </div>
                          // <div className="ml_plant_normal clearfix">
                          //   <div className="ml_level_sect clearfix">
                          //     <div
                          //       className={`ml_chart_alert h${Math.floor((obj.red / obj.total) * 100)
                          //         } n${obj.red}`}></div>
                          //     <div
                          //       className={`ml_chart_off h${Math.floor(
                          //         ((obj.grey / obj.total) * 100)
                          //       )
                          //         } n${obj.grey}`}></div>
                          //     <div
                          //       className={`ml_chart_warn h${Math.floor(
                          //         ((obj.yellow / obj.total) * 100)
                          //       )
                          //         } n${obj.yellow}`}></div>
                          //     <div
                          //       className={`ml_chart_nor h${Math.floor(
                          //         ((obj.green / obj.total) * 100)
                          //       )
                          //         } n${obj.green}`}></div>
                          //   </div>
                          // </div>
                        )}
                      </a>
                      <div className="ml_plant_sub clearfix">
                        <span className="txt_plant_name">{obj.plantCode}</span>
                        <br />
                        {obj.isAbnormal ? (
                          <span className="txt_plant_status abnor_status">
                            Abnormal
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <>
                  <div className="ml_plant_group clearfix">
                    <div className="ml_plant_col clearfix">
                      <div className="ml_plant_sub clearfix">
                        <Skeleton.Input active size="small" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          id="7spps"
          className={
            openCity === "7spps"
              ? "tabcontent show w3-animate-opacity"
              : "tabcontent w3-animate-opacity"
          }>
          <div className="ml_7spps_nav ml_plant_row_nav clearfix">
            <div className="ml_plant_group clearfix">
              {plantOverview ? (
                plantOverview
                  .filter((e) => e.plantGroup === "7SPPs")
                  .map((obj, index) => (
                    <div
                      className="ml_plant_col_nav clearfix"
                      key={obj.plantCode}>
                      <a onClick={() => getEquipmentByPlant(obj.plantCode)}>
                        {obj.isAbnormal ? (
                          <div className="ml_plant_abnormal clearfix">
                            <div className="ml_plant_count">{obj.icount}</div>
                            <img src={IconPlantWh} className="img_thumbs" />
                            <div className="plant_abnor_overlay">
                              <div className="normal_img">
                                <i className="icon-exclamation-outline"></i>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="ml_plant_normal clearfix">
                          <div className="ml_level_sect clearfix">
                              <div
                                  className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)
                                  } n${obj.normal}`}></div>
                              <div
                                  className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                  } n${obj.warning}`}></div>
                              {/* { StateStatus == "2" ?
                                  ( */}
                                      <div
                                          className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                          } n${obj.alert}`}></div>
                                  {/* ) :
                                  ( <div> </div>)
                              } */}

                              
                                      <div
                                          className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                          } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                      
                              
                              
                          </div>
                      </div>
                          
                          // <div className="ml_plant_normal clearfix">
                          //   <div className="ml_level_sect clearfix">
                          //     <div
                          //       className={`ml_chart_alert h${Math.floor(
                          //         ((obj.red / obj.total) * 100)
                          //       )
                          //         } n${obj.red}`}></div>
                          //     <div
                          //       className={`ml_chart_off h${Math.floor(
                          //         ((obj.grey / obj.total) * 100)
                          //       )
                          //         } n${obj.grey}`}></div>
                          //     <div
                          //       className={`ml_chart_warn h${Math.floor(
                          //         ((obj.yellow / obj.total) * 100)
                          //       )
                          //         } n${obj.yellow}`}></div>
                          //     <div
                          //       className={`ml_chart_nor h${Math.floor(
                          //         ((obj.green / obj.total) * 100)
                          //       )
                          //         } n${obj.green}`}></div>
                          //   </div>
                          // </div>
                        )}
                      </a>
                      <div className="ml_plant_sub clearfix">
                        <span className="txt_plant_name">{obj.plantCode}</span>
                        <br />
                        {obj.isAbnormal ? (
                          <span className="txt_plant_status abnor_status">
                            Abnormal
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <>
                  <div className="ml_plant_group clearfix">
                    <div className="ml_plant_col clearfix">
                      <div className="ml_plant_sub clearfix">
                        <Skeleton.Input active size="small" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          id="12spps"
          className={
            openCity === "12spps"
              ? "tabcontent show w3-animate-opacity"
              : "tabcontent w3-animate-opacity"
          }>
          <div className="ml_12spps_nav ml_plant_row_nav clearfix">
            <div className="ml_plant_group clearfix">
              {plantOverview ? (
                plantOverview
                  .filter((e) => e.plantGroup === "12SPPs")
                  .map((obj, index) => (
                    <div
                      className="ml_plant_col_nav clearfix"
                      key={obj.plantCode}>
                      <a onClick={() => getEquipmentByPlant(obj.plantCode)}>
                        {obj.isAbnormal ? (
                          <div className="ml_plant_abnormal clearfix">
                            <div className="ml_plant_count">{obj.icount}</div>
                            <img src={IconPlantWh} className="img_thumbs" />
                            <div className="plant_abnor_overlay">
                              <div className="normal_img">
                                <i className="icon-exclamation-outline"></i>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="ml_plant_normal clearfix">
                          <div className="ml_level_sect clearfix">
                              <div
                                  className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)
                                  } n${obj.normal}`}></div>
                              <div
                                  className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                  } n${obj.warning}`}></div>
                              {/* { StateStatus == "2" ?
                                  ( */}
                                      <div
                                          className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                          } n${obj.alert}`}></div>
                                  {/* ) :
                                  ( <div> </div>)
                              } */}

                              
                                      <div
                                          className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                          } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                      
                              
                              
                          </div>
                      </div>
                          // <div className="ml_plant_normal clearfix">
                          //   <div className="ml_level_sect clearfix">
                          //     <div
                          //       className={`ml_chart_alert h${Math.floor(
                          //         ((obj.red / obj.total) * 100)
                          //       )
                          //         } n${obj.red}`}></div>
                          //     <div
                          //       className={`ml_chart_off h${Math.floor(
                          //         ((obj.grey / obj.total) * 100)
                          //       )
                          //         } n${obj.grey}`}></div>
                          //     <div
                          //       className={`ml_chart_warn h${Math.floor(
                          //         ((obj.yellow / obj.total) * 100)
                          //       )
                          //         } n${obj.yellow}`}></div>
                          //     <div
                          //       className={`ml_chart_nor h${Math.floor(
                          //         ((obj.green / obj.total) * 100)
                          //       )
                          //         } n${obj.green}`}></div>
                          //   </div>
                          // </div>
                        )}
                      </a>
                      <div className="ml_plant_sub clearfix">
                        <span className="txt_plant_name">{obj.plantCode}</span>
                        <br />
                        {obj.isAbnormal ? (
                          <span className="txt_plant_status abnor_status">
                            Abnormal
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <>
                  <div className="ml_plant_group clearfix">
                    <div className="ml_plant_col clearfix">
                      <div className="ml_plant_sub clearfix">
                        <Skeleton.Input active size="small" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          id="ipps"
          className={
            openCity === "ipps"
              ? "tabcontent show w3-animate-opacity"
              : "tabcontent w3-animate-opacity"
          }>
          <div className="ml_ipps_nav ml_plant_row_nav clearfix">
            <div className="ml_plant_group clearfix">
              {plantOverview ? (
                plantOverview
                  .filter((e) => e.plantGroup === "IPPs")
                  .map((obj, index) => (
                    <div
                      className="ml_plant_col_nav clearfix"
                      key={obj.plantCode}>
                      <a onClick={() => getEquipmentByPlant(obj.plantCode)}>
                        {obj.isAbnormal ? (
                          <div className="ml_plant_abnormal clearfix">
                            <div className="ml_plant_count">{obj.icount}</div>
                            <img src={IconPlantWh} className="img_thumbs" />
                            <div className="plant_abnor_overlay">
                              <div className="normal_img">
                                <i className="icon-exclamation-outline"></i>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="ml_plant_normal clearfix">
                          <div className="ml_level_sect clearfix">
                              <div
                                  className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)
                                  } n${obj.normal}`}></div>
                              <div
                                  className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                                  } n${obj.warning}`}></div>
                              {/* { StateStatus == "2" ?
                                  ( */}
                                      <div
                                          className={`ml_chart_alert h${Math.round((obj.alert * 100) / 9)
                                          } n${obj.alert}`}></div>
                                  {/* ) :
                                  ( <div> </div>)
                              } */}

                              
                                      <div
                                          className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                                          } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                                      
                              
                              
                          </div>
                      </div>
                          // <div className="ml_plant_normal clearfix">
                          //   <div className="ml_level_sect clearfix">
                          //     <div
                          //       className={`ml_chart_alert h${Math.floor(
                          //         ((obj.red / obj.total) * 100)
                          //       )
                          //         } n${obj.red}`}></div>
                          //     <div
                          //       className={`ml_chart_off h${Math.cflooril(
                          //         ((obj.grey / obj.total) * 100)
                          //       )
                          //         } n${obj.grey}`}></div>
                          //     <div
                          //       className={`ml_chart_warn h${Math.floor(
                          //         ((obj.yellow / obj.total) * 100)
                          //       )
                          //         } n${obj.yellow}`}></div>
                          //     <div
                          //       className={`ml_chart_nor h${Math.floor(
                          //         ((obj.green / obj.total) * 100)
                          //       )
                          //         } n${obj.green}`}></div>
                          //   </div>
                          // </div>
                        )}
                      </a>
                      <div className="ml_plant_sub clearfix">
                        <span className="txt_plant_name">{obj.plantCode}</span>
                        <br />
                        {obj.isAbnormal ? (
                          <span className="txt_plant_status abnor_status">
                            Abnormal
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <>
                  <div className="ml_plant_group clearfix">
                    <div className="ml_plant_col clearfix">
                      <div className="ml_plant_sub clearfix">
                        <Skeleton.Input active size="small" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ComponentNotification = () => {
    return (
      <div
        id="myDropdown"
        className={
          openNotifi ? "dropdown-content-noti show" : "dropdown-content-noti "
        }
        onMouseLeave={(e) => {
          setOpentNotifi(false);
          setOpentPlant(false);
        }}>
        {notifi && notifi.length ? (
          <div className="layout-noti">
            <h2>NOTIFICATION</h2>
            {notifi.map((obj, index) => (
              <Link
                to={{
                  pathname: `/equipment/${obj.plantCode}`,
                  search: `?latestTime=${obj.latestDateTime}&equipId=${obj.equipId}&recordId=${obj.recordId}`,
                }}
                className="text-noti"
                key={index}>
                <p className="text">
                  Plant : <strong>{obj.plantCode}</strong>
                </p>
                <p className="text">
                  Equipment : <strong>{obj.equipName}</strong>
                </p>
                <p className="text">
                  Time :{" "}
                  <strong>{`${moment(obj.latestDateTime).format(
                    "MMM, DD, YYYY HH:mm"
                  )}`}</strong>
                </p>
              </Link>
            ))}
          </div>
        ) : (
          <></>
          // <div className="no-data">
          //   <p>No Notification</p>{" "}
          // </div>
        )}
      </div>
    );
  };

  const downloadManualFile = () => {
    props.DownloadManualFile()
  };

  return (
    <section className="solar_container clearfix">
      <div id="header" className="top_content_bg clearfix">
        <div className="top_content clearfix">
          <div className="top_logo clearfix">
            <Link to="/">
              <i className="icong-gulf_logo_wh ico_clogo"></i>
            </Link>
          </div>
          <ComponnentMenuPlant />
          <div className="top_menu clearfix">
            <div className="profile_col">
              <div className="ml_prof_avatar">
                <div className="round_frame_avar">
                  <img src={IconUser} className="profile_avatar_img" />
                </div>
              </div>

              <div className="ml_prof_name">
                <div className="prof_htxt ">{name}</div>
                <div className="prof_stxt"></div>
              </div>
            </div>

            <div className="ml_menu_col">
              <ul className="gulf1_menu gulf1_link clearfix">
                <li>
                  <Link to="/">
                    <div className="nav_top_menu">
                      <img src={IconHome} className="img_nav" />
                      <div className="nav_top_ico">
                        <div className="nav_top_img">
                          <img src={IconHomeGreen} className="img_nav" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div
                    className="nav_top_menu"
                    onClick={() => {
                      setOpentPlant(!openPlant);
                      setOpentNotifi(false);
                    }}>
                    <img src={IconPlant} className="img_nav" />
                    <div className="nav_top_ico">
                      <div className="nav_top_img">
                        <img src={IconPlantBlGreen} className="img_nav" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/history">
                    <div className="nav_top_menu">
                      <img src={IconHistory} className="img_nav" />
                      <div className="nav_top_ico">
                        <div className="nav_top_img">
                          <img src={IconHistoryGreen} className="img_nav" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div
                    className="nav_top_menu"
                    onClick={() => {
                      setOpentNotifi(!openNotifi);
                      setOpentPlant(false);
                    }}>
                    <img src={IconAlert} className="img_nav" />
                    <div className="nav_top_ico">
                      <div className="nav_top_img">
                        <Link to={"/issue_summary"}>
                          <img src={IconAlertGreen} className="img_nav" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <Spin spinning={props.machineLearning.manualFiledownloading}>
                    <div className="nav_top_menu" >
                      <img src={IconDownload} className="img_nav" />
                      <div className="nav_top_ico">
                        <div className="nav_top_img">
                          <Tooltip title={"Manual File"}>
                            <img src={IconDownloadGreen} className="img_nav"
                              onClick={() => {
                                downloadManualFile();
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </li>

                <li>
                  <a onClick={() => handleLogout(instance)}>
                    <div className="nav_top_menu">
                      <img src={IconLogout} className="img_nav" />
                      <div className="nav_top_ico">
                        <div className="nav_top_img">
                          <img src={IconGulfGreen} className="img_nav" />
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  ServiceGetRumtime: (props) => {
    dispatch(ServiceGetRumtime(props));
  },
  ServiceGetUserNotification: (props) => {
    dispatch(ServiceGetUserNotification(props));
  },
  DownloadManualFile: (props) => {
    dispatch(ServiceDownloadManualFile(props));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
