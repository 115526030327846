export const IconAlertGreen = require("./img/alert_gr_ico.svg").default;
export const IconAlert = require("./img/alert_ico.svg").default;
export const IconEquipment = require("./img/equipment_ico.svg").default;
export const IconGearboxGreen = require("./img/gearbox_gr_ico.svg").default;
export const IconGearbox = require("./img/gearbox_ico.svg").default;
export const IconHomeGreen = require("./img/home_gr_ico.svg").default;
export const IconHome = require("./img/home_ico.svg").default;
export const IconGulf = require("./img/logo_gulf_w.svg").default;
export const IconGulfGreen = require("./img/logout_gr_ico.svg").default;
export const IconLogout = require("./img/logout_ico.svg").default;
export const PlantBl = require("./img/plant_bl.svg").default;
export const IconPlantBlGreen = require("./img/plant_gr_ico.svg").default;
export const IconPlant = require("./img/plant_ico.svg").default;
export const IconPlantWh = require("./img/plant_wh.svg").default;
export const IconSettingGreen = require("./img/setting_gr_ico.svg").default;
export const IconSetting = require("./img/setting_ico.svg").default;
export const IconHistory = require("./img/history_ico.svg").default;
export const IconHistoryGreen = require("./img/history_gr_ico.svg").default;

export const AlertInProgress = require("./img/inprogress_ico.svg").default;
export const AlertClosed = require("./img/close_stat_ico.svg").default;
export const AlertOpen = require("./img/open_stat_ico.svg").default;
export const AlertOff = require("./img/off_stat_ico.svg").default;

export const RuntimeInProgressNormal =
  require("./img/inprogress_nor_ico.svg").default;
export const RuntimeClosed = require("./img/closed_ico.svg").default;
export const RuntimeOpen = require("./img/open_ico.svg").default;
export const RuntimeOff = require("./img/off_ico.svg").default;

export const ChangeEna = require("./img/change_ena_ico.svg").default;
export const ChangeDisa = require("./img/change_disa_ico.svg").default;

export const ImageAvatar = require("./img/avatar_img.jpg");
export const ImageAvatar2 = require("./img/avatar_img.png");
export const BgImage = require("./img/bg_img.png");
export const MainPic = require("./img/main_pic.png");
export const LineBgPic = require("./img/line_bg.png");
export const SigninPic = require("./img/signin_bg.png");
export const EquipPic = require("./img/equip_top_img.png");
export const EquipIco = require("./img/equip_ico.svg").default;
export const MlDigital = require("./vid/ml_digital.mp4");
export const MlDigitalWebm = require("./vid/ml_digital.webm");
export const IconUser = require("./img/user.svg").default;

export const DateIco = require("./img/b_date_ico.svg").default;
export const TimeIco = require("./img/b_time_ico.svg").default;
export const OffIco = require("./img/b_off_ico.svg").default;
export const WarningIco = require("./img/b_warn_ico.svg").default;
export const AlertIco = require("./img/b_alert_ico.svg").default;

export const RuntimeIco = require("./img/b_runtime_ico.svg").default;
export const CaseBtn = require("./img/case_issue_btn.svg").default;

export const HalfChart = require("./img/half_chart_img.svg").default;
export const HalfChart2 = require("./img/half_chart_img2.svg").default;

export const SearchIco = require("./img/search_ico.svg").default;
export const SensorDetect = require("./img/sensors_detect_img.jpg");

export const casestatusopen = require("./img/caseopen.svg").default;
export const casestatusclose = require("./img/caseclose.svg").default;
// export const New = require("./img/paper.svg").default;
export const New = require("./img/Edit_iCon.png");
export const New2 = require("./img/New.svg").default;
export const Assigned = require("./img/paper.svg").default;
export const Acknowledge = require("./img/Acknowledge.svg").default;
export const Pending = require("./img/Pending.svg").default;
export const Clear = require("./img/Clear.svg").default;

export const IconDownload = require("./img/icon_download.svg").default; 
export const IconDownloadGreen = require("./img/icon_download_green.svg").default; 

