import React, { useState, useEffect } from "react";
import { DatePicker, Spin, Skeleton, Tooltip, Table,Select, Modal } from "antd";
import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import AnomalyChart from "../components/AnomalyChart";
import AnomalyChartGraph from "../components/AnomalyChartGraph";
import AnomalyChartGraphSum from "../components/AnomalyChartGraphSum";
import BarChart  from "../components/Graph";
import PlantStatus from "../components/PlantStatus";
import moment from "moment";
import CaseIssue from "../components/CaseIssue";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Collapse, Button, message } from 'antd';
import { CaretUpOutlined, 
          CaretDownOutlined,
          AppstoreOutlined,
          ContainerOutlined,
          DesktopOutlined,
          MailOutlined,
          MenuFoldOutlined,
          MenuUnfoldOutlined,
          PieChartOutlined,
          SearchOutlined,
          RedoOutlined,
          ReloadOutlined
} from '@ant-design/icons';
import {
  BgImage, IconEquipment, EquipPic,
  MainPic, DateIco, TimeIco,
  OffIco, WarningIco, AlertIco,
  RuntimeIco, CaseBtn, HalfChart, SearchIco,
  AlertInProgress, //alert yellow
  AlertClosed, // alert green
  AlertOff, // alert grey
  AlertOpen, // alert open
  RuntimeInProgressNormal, //runtime yellow
  RuntimeClosed, //runtime green
  RuntimeOpen, //runtime red
  RuntimeOff, // runtime grey
  casestatusopen, casestatusclose, New,New2, Assigned,
  Acknowledge, Pending, Clear,
  EquipIco
} from "../assets/images";
import { useParams, useLocation, Link, NavLink } from "react-router-dom";

import {
  ServiceGetHealthScore,
  ServiceGetAnomaly,
  ServiceGetEquipmentOverviewByPlantEnhance,
  ServiceGetPlantStatus,
  ServiceGetDetail,
  ServiceEnhGetIssueSummary,
  ServiceGetLogUpdateIssueLast,
  ServiceGetSensor,
  ServiceGetSensorSum,
  ServiceGetRanking,
  ServiceGetIssure,
  ServiceGetSensorV2,
  ServiceGetRumtime,
  ServiceGetAlert,
  ServiceEnhGetAllEquip,
  ServiceGetIssurebyEquipID,
  ServiceEnhGetSensorByparam
} from "../services/machine.learning.service";
import {
  ActionClearAnomaly,
  ActionClearPlantStatus,
  ActionClearDetail,
  ActionGetRanking,
  ActionClearByparam,
  ActionClearSensorSum,
  ActionClearRanking
} from "../redux/action/action.machine.learning";

// import "../assets/css/custom-antdesign.css";
import "antd/dist/antd.min.css";

import "../assets/css/equipmentNew.css";
import "../assets/css/main.css";
import "../assets/css/style.css";
import HealthScoreGauge from "../components/HealthScoreGauge";
const { Panel } = Collapse;
const getDataAlert = (value) => {
  if (value === -1) {
    return { image: AlertOff, name: "Off" };
  }
  if (value === 0) {
    return { image: AlertClosed, name: "Normal" };
  }

  if (value === 1) {
    return { image: AlertInProgress, name: "Warning" };
  }
  if (value === 2) {
    return { image: AlertOpen, name: "Alert" };
  }
};

const getDataPriorty = (value) => {
  if (value === null) {
    return { image: AlertOff, name: "Unassigned" };
  }
  if (value === 0) {
    return { image: AlertClosed, name: "Normal" };
  }

  if (value === 1) {
    return { image: AlertInProgress, name: "Pending" };
  }
  if (value === 2) {
    return { image: AlertOpen, name: "High" };
  }
};

const getDataRuntime = (value) => {
  if (value === null) {
    return { image: RuntimeOff, name: "Off" };
  }
  if (value === "N") {
    return { image: RuntimeClosed, name: "Normal" };
  }

  if (value === "W") {
    return { image: RuntimeInProgressNormal, name: "Warning" };
  }
  if (value === "A") {
    return { image: RuntimeOpen, name: "Alert" };
  }
};
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  return formattedDate;
}


const styleTable = {
  text: {
    color: "#fff",
  },
  hText: {
    color: "#004370",
    fontWeight: "bold",
  },
};

const sortDataByEquipmentName = (data) => {
  return data.sort((a, b) => a.equipmentName.localeCompare(b.equipmentName));
};
const sortDataByScoreOutliner = (data) => {
  return data.sort((a, b) => b.scoreOutliner - a.scoreOutliner);
};
const EquipmentNew = (props) => {

  const [isLoadingAnomaly, setIsLoadingAnomaly] = useState(true);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingPlantStatus, setIsLoadingPlantStatus] = useState(true);
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  let selectedIndex;
  let selectedEquipId;

  const dateFormat = "YYYY/MM/DD";
  const dataFormatApi = "YYYY-MM-DDTHH:mm:ss";
  let today = new Date();
  today.setDate(today.getDate() -1)
  const equipment = useParams();

  const [date, setDate] = useState(
    moment().subtract(1, "day").format(dataFormatApi)
  );

  const [caseData, setCaseData] = useState();
  const [visible, setVisible] = useState(false);



  const dispatch = useDispatch();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  let reduxEquipmentOverview = useSelector(
    (state) => state.machineLearning.equipmentOverview
  );
  let reduxHealthScore = useSelector(
    (state) => state.machineLearning.healthScore
  );
  let reduxAnomaly = useSelector((state) => state.machineLearning.anomaly);
  let reduxPlantStatus = useSelector(
    (state) => state.machineLearning.plantStatus
  );
  let reduxissuesummary = useSelector(
    (state) => state.machineLearning.IssueSummary
  );

  let reduxDetail = useSelector((state) => state.machineLearning.detail);
  let equipmentid = "";

  let [equipmentOverview, setEquipmentOverview] = useState(null);
  let [healthScore, setHealthScore] = useState(null);
  let [anomaly, setAnomaly] = useState(null);
  let [plantStatus, setPlantStatus] = useState(null);
  let [detail, setDetail] = useState(null);
  let [detailData, setDetailData] = useState(null);
  const [issuesummary, setissuesummary] = useState(null);
  const [issuesummary2, setissuesummary2] = useState(null);
  let [fiterissure, setfiterissue] = useState(null);

  let [dataFromPlantStatus, setDataFromPlantStatus] = useState(null);
  const [columns, setColumns] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(0);
  const [selectedSort, setSelectedSort] = useState(0);

  const [dis, setdis] = useState(null);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadType, setLoadType] = useState(null);
  const [selectedSensorsData, setSelectedSensorsData] = useState(null);
  const [sensor, setSensor] = useState(null);
  const [sensornew, setSensornew] = useState(null);
  const [datesensor, setDatesensor] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [StateStatus, setStateStatus] = useState(1);
  const [sensorv2, setsensorv2] = useState([]);

  const reduxSensor = useSelector((state) => state.machineLearning.sensor);
  const reduxSensorSum = useSelector((state) => state.machineLearning.sensorsum);
  const reduxSensorv2 = useSelector((state) => state.machineLearning.SensorV2);
  const reduxSensorGraph = useSelector((state) => state.machineLearning.SensorGraph);
  const reduxRanking = useSelector((state) => state.machineLearning.ranking);

  let [anomalyGraph, setAnomalyGraph] = useState(null);
  let [anomalyGraphSum, setAnomalyGraphSum] = useState(null);
  let [Graphtang, setGraphtang] = useState(null);
  const [spinning, setSpinning] = React.useState(false);
  const [spinning1, setSpinning1] = React.useState(false);
  const [displayname, setdisplayname] = useState(null);
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const [equipmentDetail, setEquipmentDetail] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [showrEq, setshowEq] = useState(null)
  let [equid, setEquid] = useState(null)
  const [spinningGraph, setspinningGraph] = useState(null)
  const [loaddata, setloaddata] = useState(null)
  const [graphData, setGraphData] = useState([]);
  const [dataloadday, setdataloadday] = useState([]);
  const [showplant, setShowplan] = useState(null);
  const [showEquip, setshowEquip] = useState(null);

  
  const reduxruntme = useSelector(
    (state) => state.machineLearning.runtime
);

const reduxalert = useSelector(
    (state) => state.machineLearning.alert
);
const [isModalOpen, setIsModalOpen] = useState(false);

const reduxMlIssue = useSelector(
  (state) => state.machineLearning.mlissue
);

const reduxEquip = useSelector(
  (state) => state.machineLearning.equid
);

  function statusimg(status) {

    switch (status) {
      case 0:
        return New;
      case 1:
        return Assigned;
      case 2:
        return Acknowledge;
      case 3:
        return Pending;
      case 4:
        return Clear;
      default:
        return 'New';
    }
  };

  function statusimg2(status) {

    switch (status) {
      case 0:
        return New2;
      case 1:
        return Assigned;
      case 2:
        return Acknowledge;
      case 3:
        return Pending;
      case 4:
        return Clear;
      default:
        return 'New';
    }
  };
  function statustotext(status) {

    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'Assigned';
      case 2:
        return 'Acknowledge';
      case 3:
        return 'Pending';
      case 4:
        return 'Clear';
      default:
        return 'New';
    }
  };

  const handleOpenCase = (obj) => {
    // console.log('handleOpenCase',obj)
    setCaseData(obj);
    setVisible(true);
    dispatch(ServiceGetLogUpdateIssueLast(obj.issue_Id));
  };

  const handleChange = (even) => {
       console.log("even",typeof(even))
    setStateStatus(parseInt(even, 10))

  };

  

  const itemDistinct = async (item) => {
    // console.log("item",item)
    // const dis = item.find(x => x.paramDescription) 
     const result = await Array.from(new Set(item.map(s => s.paramId)))
          .map(paramId => {
            return {
               paramId: item.find(s => s.paramId === paramId),
          }
        })

    setdis(result)
    return result
  };

  const dataGraph = async (dataSensor) => {
    setspinningGraph(true); 

    //  console.log("datasSensrot",dataSensor[0])
     console.log("datasSensrot",dataSensor.length)


    try {
       await setAnomalyGraph(dataSensor);
         setSensornew(dataSensor)
        // console.log("datasSensrotTRY",dataSensor)
        // if (dataSensor.length < 0) {
        //       //  await setAnomalyGraph(dataSensor);
        //     // If you need to set dates for each sensor data entry, do it here
        //     // Example:
        //     // dataSensor.forEach((el) => {
        //     //     setDatesensor(el.paramDatetime);
        //     //     console.log('setDatesensor', el.paramDatetime);
        //     // });

        //     // If dataSensor has a property `paramDatetime` that needs formatting
        //     // setDatesensor(dataSensor.paramDatetime.format(dataFormatApi));
        //     // console.log('dataSensordate', dataSensor.paramDatetime.format(dataFormatApi));
        // }
    } catch (error) {
        console.error('Error processing dataGraph:', error);
    } finally {
        setspinningGraph(false); 
    }
};
// console.log("spin",spinning)

  // useEffect(() => {
  //   // Subtract 67 days from the current date
  //   const newDate = new Date();
  //   newDate.setDate(newDate.getDate() - 67);
  //   setDate(newDate);
  // }, []);
  // get issue sumnary

  // useEffect(() => {
  //     setequit(ServiceEnhGetAllEquip(equipment.plantCode));
  // },[equipment.plantCode])

  useEffect(() => {
    dispatch(
      ServiceEnhGetIssueSummary(
        equipment.plantCode
      )
    );
  }, [equipment.plantCode]);
  //! GET REDUX EQUIPMENT

  useEffect(() => {
    dispatch(
      ServiceGetEquipmentOverviewByPlantEnhance(
        JSON.stringify({ plantCode: equipment.plantCode, date: date })
      )
    );
  }, [equipment.plantCode]);

  //! GET REDUX HEALTHSCORE

  useEffect(() => {
    dispatch(
      ServiceGetHealthScore(
        JSON.stringify({ plantCode: equipment.plantCode, date: date })
      )
    ); 
  }, [loaddata]); //[date]
  
  //! GET REDUX SENSOR
  // useEffect(() => {
  //   dispatch(
  //       ServiceGetSensor(
  //           JSON.stringify({
  //             plantCode: equipment.plantCode,
  //             equiP_ID: 1,
  //             date: date,
  //             Day:StateStatus
  //           })
  //       )
  //   );
  // }, [date],[StateStatus]);
  // useEffect(() => {
  //    ServiceEnhGetAllEquip(equipment.plantCode)
  //   // setEquid(reduxEquip)
  //   // setEquid(result.data[0].equipId);
  // }, []);

  useEffect(() => {
    const fetchEquip = async () => {
      try {
           const result = await ServiceEnhGetAllEquip(equipment.plantCode);
           console.log('Fetched equipment:', result.data[0].equipId); // Debug log
          //  setEquid(result.data[0].equipId);
          //  console.log('Fetched:', equid); // Debug log
          // setEquid(result.data[0].equipId);
        
      } catch (error) {
          console.error('Error fetching equipment:', error);
      }
  };

  dispatch(ActionClearSensorSum());
  dispatch(ActionClearRanking());
  dispatch(ActionClearByparam());

  fetchEquip();
    
  },[equipment.plantCode])

    //! GET REDUX Ranking
    useEffect(() => {
      const fetchEquip = async () => {
        try {
          setSpinning(true);
             const result = await ServiceEnhGetAllEquip(equipment.plantCode);
            //  setEquid(result.data[0].equipId);
            const payload = JSON.stringify({
              plantCode: equipment.plantCode,
              equiP_ID: result.data[0].equipId,
              date: date,
              Day: StateStatus
          });
   
          // dispatch(ServiceGetRanking(payload));
          // dispatch(ServiceGetSensor(payload));
          // dispatch(ServiceGetSensorSum(payload));
          dispatch(ServiceGetSensorV2(payload));
          // dispatch(ServiceGetSensorV2(payload));
          

          const equipId = result.data[0].equipId;
          setAnomalyGraphSum(null);
   
          //  setStateOpenKeys([String(equipId)]);
          //  setEquid(result.data[0].equipId);
          
        } catch (error) {
            console.error('Error fetching equipment:', error);
        }
        setSpinning(false);
    };
  
    fetchEquip();
    // setStateOpenKeys([equid]);
      // if(equid == 1) {
          // console.log('Dispatching actions with equid:', equipment.plantCode);
//    else {
//     console.log('Equid is not ready or empty'); // Debug log
// }
    //     dispatch(
    //       ServiceGetRanking(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: equid[0].equipId,
    //             date: date,
    //             Day:StateStatus
    //           })
    //       )
    //   );
    //   dispatch(
    //       ServiceGetSensor(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: equid[0].equipId,
    //             date: date,
    //             Day:StateStatus
    //           })
    //       )
    //   );
    //   dispatch(
    //     ServiceGetSensorSum(
    //         JSON.stringify({
    //           plantCode: equipment.plantCode,
    //           equiP_ID: equid[0].equipId,
    //           date: date,
    //           Day:StateStatus
    //         })
    //     )
    // );
    //   dispatch(
    //       ServiceGetSensorV2(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: equid[0].equipId,
    //             date: date,
    //             Day:StateStatus
    //           })
    //       )
    //   );
      
       
        // setStateOpenKeys([reduxSensorv2[0].key]);
        // setequit(reduxSensorv2[0].key);
    }, []); //[date]

    useEffect(() => {
      dispatch(
        ServiceGetIssure(
              JSON.stringify({
                plantCode: equipment.plantCode,
                equiP_ID: 1,
                date:date,
              })
          )
      );
    }, [loaddata]); //[date]
    
  useEffect(() => {
    const fetchData = async () => {
        // setSpinning(true); 

        try {
            if (Array.isArray(reduxSensor) && reduxSensor.length > 0 && reduxSensor != null) {
                setSensor(reduxSensor);
                
                await itemDistinct(reduxSensor); 
                  setEquipmentDetail(reduxSensor);
                
                //  await  setGraphtang(reduxRanking);
                // setshowEquip = reduxSensor[0].equipNameDisplay;
                // setShowplan= reduxSensor[0].plantCode;
                // console.log("FFFFF",reduxSensor[0].equipNameDisplay)
            }
        } catch (error) {
            console.error('Error handling sensor data:', error);
        } finally {
            // setSpinning(false); 
        }
    };

    fetchData();
}, [date,reduxSensor]);
// console.log("spin593",spinning)
  useEffect(() => {
    // setSpinning(true);
    if(Array.isArray(reduxSensorv2) && reduxSensorv2.length > 0 && reduxSensorv2!=null){
      // setSpinning(false);
       setsensorv2(reduxSensorv2);
        // console.log("karunv3",reduxSensorv2)
     
    }
    
  }, [reduxSensorv2]);
  // console.log("spin604",spinning)
  useEffect(() => {
    
    if(Array.isArray(reduxSensorSum) && reduxSensorSum.length > 0 && reduxSensorSum!=null){
   
      setAnomalyGraphSum(reduxSensorSum);
      // setEquipmentDetail(reduxSensorSum);
      // setGraphtang(reduxRanking);
      // dataGraph(reduxSensorSum)
      // console.log("reduxSensorSum581",reduxSensor)
      
    
    }
    
  }, [reduxSensorSum]);

 useEffect(() => {
    const fetchData = async () => {
     await setGraphtang(reduxRanking);
    };
    fetchData();
  }, [reduxRanking]);


  useEffect(() => {
    if (Array.isArray(dis) && dis.length > 0 && dis !=null) {
      //  console.log("doit",dis)
      setSelectedSensorsData(
          selectedSensors.map((item) => {    
            return dis[item].paramId.paramId;
          })
      );
    }
    // console.log("karun",selectedSensorsData)
    // console.log("karun2",reduxSensor)
    // console.log("karun3",dis)
  }, [dis, selectedSensors]);


  //! GET INITIAL ANOMALY

  useEffect(() => {
    if (reduxHealthScore !== null) {
      if (reduxHealthScore.length !== 0) {
        (async () => {
          await dispatch(
            ServiceGetAnomaly(
              JSON.stringify({
                plantCode: equipment.plantCode,
                date: date,
                equiP_ID: reduxHealthScore[0].equipId,
              })
            )
          );
          setIsLoadingAnomaly(false);
        })();
      } else {
        dispatch(ActionClearAnomaly());
      }
    } else {
      dispatch(ActionClearAnomaly());
    }
  }, []);


  useEffect(() => {
    setEquipmentOverview(reduxruntme);
  }, [reduxruntme]);

  useEffect(() => {
     dispatch(ServiceGetRumtime());
    dispatch(ServiceGetAlert());
    setIsLoading(false);
    setissuesummary(reduxMlIssue);
    showdata(reduxMlIssue)

    //  console.log("reduxMlIssue2",reduxMlIssue)
  }, [reduxMlIssue]);

  useEffect(() => {
    if (reduxHealthScore != null) {
      if (reduxHealthScore.length !== 0) {
        if (selectedSort == 0) {
          reduxHealthScore.sort((a, b) => b.scoreOutliner - a.scoreOutliner);
          setHealthScore(reduxHealthScore);
        }
        if (selectedSort == 1) {
          reduxHealthScore.sort((a, b) =>
            a.equipmentName.localeCompare(b.equipmentName)
          );
          setHealthScore(reduxHealthScore);
        }
      }
    }

    setHealthScore(reduxHealthScore);
  }, [reduxHealthScore]);
  useEffect(() => {
    setAnomaly(reduxAnomaly);
  }, [reduxAnomaly]);
  useEffect(() => {
    setPlantStatus(reduxPlantStatus);
    if (reduxPlantStatus !== null) {
      if (reduxPlantStatus.length > 0) {
        setDataFromPlantStatus(reduxPlantStatus[reduxPlantStatus.length - 1]);
      }
    }
  }, [reduxPlantStatus]);

  // useEffect(() => {
  //   setDetail(reduxDetail);
  //   setIsLoadingDetail(false);
  // }, [reduxDetail]);

  // useEffect(() => {
  //   setIsLoadingDetail(true);

  //   if (dataFromPlantStatus !== null) {
  //     (async () => {
  //       await dispatch(
  //         ServiceGetDetail(
  //           JSON.stringify({
  //             recordId: dataFromPlantStatus.recordId,
  //             nearestRecordId: "string",
  //           })
  //         )
  //       );
  //       setIsLoadingDetail(false);
  //     })();
  //   } else {
  //     dispatch(ActionClearDetail());
  //     setIsLoadingDetail(false);
  //   }
  // }, [dataFromPlantStatus]);

  useEffect(() => {
    if (healthScore) {
      if (healthScore[selectedCategories].equipId) {
        (async () => {
          await dispatch(
            ServiceGetAnomaly(
              JSON.stringify({
                plantCode: equipment.plantCode,
                date: date,
                equiP_ID: healthScore[selectedCategories].equipId,
              })
            )
          );
          setIsLoadingAnomaly(false);
          // await dispatch(
          //   ServiceGetPlantStatus(
          //     JSON.stringify({
          //       plantCode: equipment.plantCode,
          //       date: date,
          //       equiP_ID: healthScore[selectedCategories].equipId,
          //     })
          //   )
          // );
          // setIsLoadingPlantStatus(false);
        })();
      }
    }
  }, [healthScore]);

  useEffect(() => {
     console.log('fetching data:',selectedSensorsData);
    // console.log('reduxSensor767:',reduxSensor);
    // console.log('reduxSensorGraph765:',reduxSensorGraph);
    if (selectedSensorsData && selectedSensorsData.length > 0 && reduxSensor && reduxSensor.length > 0) { //
      const pramid = reduxSensor.filter(sensor => sensor.paramCode === selectedSensorsData[selectedSensorsData.length - 1]);
      // console.log("pramidqqq",pramid[0]);
      if (pramid && pramid.length > 0) {
        const dataloadday1 = [pramid[0].paramId, pramid[0].equipId];
  
        // อัปเดตสถานะด้วยการกระจายค่าเดิมแล้วเพิ่มค่าใหม่
        setdataloadday(prevDataloadday => {
          // ตรวจสอบ prevDataloadday ว่าเป็นอาร์เรย์หรือไม่
          if (Array.isArray(prevDataloadday)) {
            return [...prevDataloadday, dataloadday1];
          } else {
            console.error("prevDataloadday is not an array");
            return [dataloadday1];
          }
        });
  
        console.log("setdataloadday", dataloadday1);
      } else {
        console.error("pramid is undefined or empty");
      }
      // const dataloadday1 = [pramid[0].paramId, pramid[0].equipId];
      // setdataloadday(prevDataloadday => [...prevDataloadday, dataloadday1]);
      if (pramid.length > 0) {
        (async () => {
          try {
            setIsLoading(true);
            await dispatch(ServiceEnhGetSensorByparam(
              JSON.stringify({
                plantCode: equipment.plantCode,
                equiP_ID: pramid[0].equipId,
                date: date,
                Day: StateStatus,
                paramId: pramid[0].paramId
              })
            ));
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
        })();

        // (async () => {
        //   try {
        //     setIsLoading(true);
        //     await dispatch(ServiceEnhGetSensorByparam(
        //       JSON.stringify({
        //         plantCode: equipment.plantCode,
        //         equiP_ID: pramid[0].equipId,
        //         date: date,
        //         Day: StateStatus,
        //         paramId: pramid[0].paramId
        //       })
        //     ));
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   } finally {
        //     setIsLoading(false);
        //   }
        // })();
      }

    }
    else {
      console.error('Error fetching data Loop1:');
    }
  }, [selectedSensorsData]);

  useEffect(() => {
    if (reduxSensorGraph && reduxSensorGraph.length > 0) {
      if (graphData && graphData.length > 0) {
        const updatedGraphData = [...graphData, ...reduxSensorGraph];
        setGraphData(updatedGraphData);
         dataGraph(updatedGraphData);
        //  console.log("reduxSensorGraphIF", updatedGraphData);
      } else {
        setGraphData(reduxSensorGraph);
         dataGraph(reduxSensorGraph);
        // console.log("reduxSensorGraphElse", reduxSensorGraph);
      }
    } else {
      console.error('Error: reduxSensorGraph is empty or null.');
    }

},[reduxSensorGraph])

  const success = () => {
    messageApi.open({
      type: 'warning',
      content: 'NO DATA IN TODAY',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

 const showdata = async(dataIssue) => {
  let dataalert = 0;
  let datawarnig = 0;
  let datanormal = 0;
  
  // console.log("dataIssue",dataIssue)
  for (let i in dataIssue) {
      // console.log("dataIssue",i)

      if(dataIssue[i].alertstate == 2)
        {
            dataalert = dataalert + 1;
        }
      else 
        {
          datawarnig = datawarnig + 1;
      }
     
  }
  let showdataAlert = {
    alert: dataalert,
    normal: ((dataalert + datawarnig) - 9)* -1,
    plantCode: dataIssue[0].plant_Code,
    plantGroup: "12SPPs",
    warning: datawarnig
  }
  setshowEq(showdataAlert)
  // console.log("dataAler", showdataAlert)
 }

  // useEffect(() => {
  //   if (detail) {
  //     setColumnsDetail();
  //     setRowsDetail();
  //     setIsLoadingDetail(false);
  //   }
  //   setIsLoadingDetail(false);
  // }, [detail]);
  
  const onClick = (e) => {
      // console.log("onclick",e)
    //  console.log("sensorv2",reduxSensor);
     
    // if(reduxSensor == null)
    //   {  
    //     (async () => {
    //       setSpinning(true)
    //       console.log("onclick")
    //       await dispatch(
    //         ServiceGetSensor(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: e[1],
    //             date: date,
    //             Day:StateStatus
    //           })
    //       ));
    //       setSpinning(false)
    //     }
    //   )();
    //    
    //   }
    //   else {
          //  setSpinning(true);
           try {
             // console.log("onclick",e)
            
              if (selectedSensorsData.includes(e.key)) {
                
                // ถ้า item มีอยู่ใน array เอาออก
                 setSelectedSensorsData(selectedSensorsData.filter(i => i !== e.key));
                 console.log("selectedSensorsData", e.key)
               
    
                //setSpinning(false);
              } else {
          
                // ถ้า item ไม่มีใน array เพิ่มเข้าไป
                 setSelectedSensorsData([...selectedSensorsData, e.key]);
                 console.log("selectedSensorsDataIF", e.key)

                  }
           }
           catch (error) {
            console.error('Error updating sensor data:', error);
           } finally {
            //  setSpinning(false)
           }
          
      // }    
};
  // const onSelect = (e) => {
  //   console.log("Close",e)
  //  
  // };
const onClickPlant = (e) => {
  // setSelectedSensorsData([]);
  //  setSelectedSensorsData(selectedSensorsData.filter(i => i !== e.key));
};
const getLevelKeys = (items1) => {
  const key = {};
   const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children);
       }
     });
   };
   func(items1);
  return key;
};
const levelKeys = getLevelKeys(sensorv2);
// console.log("sensorv2",sensorv2)
const onOpen = (e) => 
{
  
    // console.log("menu1",e)
    const currentKey = e.find((key) => stateOpenKeys.indexOf(key) === -1);

    // open
    if (currentKey !== undefined) {
      const repeatIndex = e.filter((key) => key !== currentKey).findIndex((key) => levelKeys[key] === levelKeys[currentKey]);
      //  console.log("repeatIndex",repeatIndex)
      setStateOpenKeys(
        e.filter((_, index) => index !== repeatIndex).filter((key) => levelKeys[key] <= levelKeys[currentKey]),
      );
         // console.log("setStateOpenKeys",setStateOpenKeys)
      
          if(e.length > 1) {
              e[0] = e[1]
              equipmentid =e[0]
              setEquid(e[0])
              console.log("E0",e[0])
          } else {
            setEquid(e[0])
          }

    } 
    else {
      // close
      //  console.log("Else",e)
     
      setStateOpenKeys(e);
    }
  //   if(e != currentKey) {
  //     console.log("ifE",e);
  //     (async () => {
  //       setSpinning(true)
  //       await dispatch(
  //         ServiceGetSensor(
  //           JSON.stringify({
  //             plantCode: equipment.plantCode,
  //             equiP_ID: e[0],
  //             date: date,
  //             Day:StateStatus
  //           })
  //       ));

  //     }
  //   )();

  //     (async () => {
  //           await dispatch(
  //               ServiceGetSensorV2(
  //                   JSON.stringify({
  //                     plantCode: equipment.plantCode,
  //                     equiP_ID: e[0],
  //                     date: date,
  //                     Day:StateStatus
  //                   })
  //               ));
  //           setSpinning(false)
  //         }
  //     )();
  
  //   (async () => {
  //     await dispatch(
  //       ServiceGetRanking(
  //         JSON.stringify({
  //           plantCode: equipment.plantCode,
  //           date: date,
  //           equiP_ID: e[0],
  //         })
  //       )
  //   )
  // }
  // )();
  //   }
    if(e.length > 0)
      {
      // console.log("length",e);
        (async () => {
          await dispatch(
            ServiceGetSensorSum(
              JSON.stringify({
                plantCode: equipment.plantCode,
                date: date,
                equiP_ID: e[0],
                Day:StateStatus
              })
            )
      
        )
      })();
      (async () => {
        onClickPlant(e)
        await dispatch(
          ServiceGetRanking(
            JSON.stringify({
              plantCode: equipment.plantCode,
              date: date,
              equiP_ID: e[0],
            })
          )
      )
    })();

    (async () => {
      setSpinning(true)
      await dispatch(
        ServiceGetSensor(
          JSON.stringify({
            plantCode: equipment.plantCode,
            equiP_ID: e[0],
            date: date,
            Day:StateStatus
          })
          
      ));
    }
  )();
  
    (async () => {
          await dispatch(
              ServiceGetSensorV2(
                  JSON.stringify({
                    plantCode: equipment.plantCode,
                    equiP_ID: e[0],
                    date: date,
                    Day:StateStatus
                  })
              ));
               setSpinning(false)

              // await dispatch(
              //   ServiceGetSensorV2(
              //       JSON.stringify({
              //         plantCode: equipment.plantCode,
              //         equiP_ID: e[0],
              //         date: date,
              //         Day:StateStatus
              //       })
              //   ));
        }
    )();
    // console.log("DDDDDDD")
    setShowplan(1)
  //  etShowplan(null);
  //  setshowEquip(null);
  
    // 
      }
};

const changDate = (selcetdate) => {
  (async () => {
    // console.log("Data",selcetdate,equipmentid)
    if(equipmentid == "")
      {
        equipmentid = sensorv2[0].key;
      }
      // console.log("equipmentid",equipmentid)
    await dispatch(
      ServiceGetSensor(
        JSON.stringify({
          plantCode: equipment.plantCode,
          equiP_ID: equipmentid,
          date: selcetdate,
          Day:StateStatus
        })
    ))
  })();
}

  const handleSelectedCategories = (index, id) => {
    setIsLoadingAnomaly(true);
    setIsLoadingPlantStatus(true);
    setIsLoadingDetail(true);
    setSelectedCategories(index);
    (async () => {
      await dispatch(
        ServiceGetAnomaly(
          JSON.stringify({
            plantCode: equipment.plantCode,
            date: date,
            equiP_ID: id,
          })
        )
      );
      setIsLoadingAnomaly(false);
      // await dispatch(
      //   ServiceGetPlantStatus(
      //     JSON.stringify({
      //       plantCode: equipment.plantCode,
      //       date: date,
      //       equiP_ID: id,
      //     })
      //   )
      // );
      // setIsLoadingPlantStatus(false);
    })();
  };
  const handleDateChange = async (date) => {
    // setIsLoadingAnomaly(true);
    // setIsLoadingPlantStatus(true);
    // setIsLoadingDetail(true);
      setDate(date.format(dataFormatApi));
      setOpen1(false);
      setOpen2(false);
    // setIsLoadingAnomaly(true)
    // setAnomalyGraph(null);
    // setAnomalyGraphSum(null);
    // changDate(date.format(dataFormatApi));
  };
  const handleDateChange1 = async () => {
    try {
    
    // console.log("try")
      // setIsLoadingAnomaly(true);
      // setIsLoadingPlantStatus(true);
      // setIsLoadingDetail(true);
      // setDate(date.format(dataFormatApi));
      // console.log('Selected date:', date ? date.format(dateFormat) : null);
      // setOpen1(false);
      // setOpen2(false);
      // setIsLoadingAnomaly(true)
      setAnomalyGraph(null);
      setAnomalyGraphSum(null);
     
      // changDate(date.format(dataFormatApi));
      setSpinning(true)
      
      // await dispatch(
      //     ServiceGetSensor(
      //         JSON.stringify({
      //           plantCode: equipment.plantCode,
      //           equiP_ID: equid,
      //           date: date,
      //           Day: StateStatus
      //         })
      //     ));

      // await dispatch(
      //     ServiceGetSensorV2(
      //         JSON.stringify({
      //           plantCode: equipment.plantCode,
      //           equiP_ID: equid,
      //           date: date,
      //           Day: StateStatus
      //         })
      //     ));
      //  console.log("AAAA",dataloadday)
      
      setIsLoading(true);

    // Fetch ServiceGetRanking
        await dispatch(ServiceGetRanking(
          JSON.stringify({
            plantCode: equipment.plantCode,
            date: date,
            equiP_ID: equid,
            Day: StateStatus
          })
        ));
        
        // Fetch ServiceGetSensorSum
        await dispatch(ServiceGetSensorSum(
          JSON.stringify({
            plantCode: equipment.plantCode,
            date: date,
            equiP_ID: equid,
            Day: StateStatus
          })
        ));

        // Loop through dataloadday and fetch ServiceEnhGetSensorByparam
        for (const element of dataloadday) {
            dispatch(ServiceEnhGetSensorByparam(
              JSON.stringify({
                plantCode: equipment.plantCode,
                equiP_ID: element[1],
                date: date,
                Day: StateStatus,
                paramId: element[0]
              })
            ));

            // dispatch(ServiceEnhGetSensorByparam(
            //   JSON.stringify({
            //     plantCode: equipment.plantCode,
            //     equiP_ID: element[1],
            //     date: date,
            //     Day: StateStatus,
            //     paramId: element[0]
            //   })
            // ));
        }
        setdataloadday(StateStatus)
        }catch (error) {
          console.error('Error in async actions:', error);
        } finally {
          // console.log("finall")
         
          dispatch(ActionClearByparam());
        }
        setIsLoading(false);
        setSpinning(false);
  };
  const handleSortByScoreClick = () => {
    setIsLoadingAnomaly(true);
    setIsLoadingPlantStatus(true);
    setIsLoadingDetail(true);
    setSelectedSort(0);
    setHealthScore((prevData) => sortDataByScoreOutliner([...prevData]));
  };

  const handleSortByAZClick = () => {
    setIsLoadingAnomaly(true);
    setIsLoadingPlantStatus(true);
    setIsLoadingDetail(true);
    setSelectedSort(1);
    setHealthScore((prevData) => sortDataByEquipmentName([...prevData]));
  };

  const PlantStatusClickHandler = (target) => {
    setDataFromPlantStatus(target._dataItem.dataContext);
  };

  const handleItemClick =  (itemIndex, paramid) => {
    // console.log("handleItemClick",itemIndex,paramid,selectedSensors)
    if (selectedSensors.includes(itemIndex)) {
       setSelectedSensors(
        selectedSensors.filter((index) => index !== itemIndex)
      );
    } else {
       setSelectedSensors([...selectedSensors, itemIndex].sort((a, b) => a - b));
    }
  };
  const handleItemClick2 =  (itemIndex, paramid) => {
    // console.log("handleItemClick2",itemIndex,selectedSensors)
    if (selectedSensors.includes(itemIndex)) {
       setSelectedSensors(
        selectedSensors.filter((index) => index !== itemIndex)
      );
    } else {
       setSelectedSensors([...selectedSensors, itemIndex].sort((a, b) => a - b));
    }
  };

  const showModal = async(equipname, index) => {
       // plantOverview
    // .filter((e) => e.plantGroup === "12SPPs")
    let equid =  issuesummary.filter((e) => e.issue_Id === equipname)
    const payload = JSON.stringify({
      plantCode: equipment.plantCode,
      equiP_ID: equid[0].equid,
      date: date,
      Day: 1
    });
    
     // console.log("equipname",equipname)
     // console.log("issuesummary",payload)

    let filter;
    await ServiceGetIssurebyEquipID(payload).then(response => {
      filter = response;
      // console.log("filter",filter.data);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
    
     // let filter =  issuesummary.filter((e) => e.issue_Id === equipname)
      
       setissuesummary2(filter.data);
      // console.log("filter",filter)
    // setfiterissue()
     setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cleardata = () => {
    setAnomaly(null);
    setAnomalyGraph(null);
    setAnomalyGraphSum(null);
  }

  function getPlantCode(paramId, dataArray) {
      // console.log("$$$$$$", dataArray);
    // setAnomaly(dataArray)
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].paramCode === paramId) {
        // return dataArray[i].plantCode;
        //  console.log("tete",`${dataArray[i].paramDescription} : ${dataArray[i].paramCode}(${dataArray[i].paramUnit}`)
        // setdisplayname(`${dataArray[i].paramDescription} : ${dataArray[i].paramCode}(${dataArray[i].paramUnit})`)
        return `${dataArray[i].paramDescription} : ${dataArray[i].paramCode}(${dataArray[i].paramUnit})`
      }
    }

    // Return null if no matching paramId was found
    return null;
  }

  // const setColumnsDetail = () => {
  //   let col = [
  //     {
  //       title: () => <p style={styleTable.text}>ITEM</p>,
  //       dataIndex: "item",
  //       key: "item",
  //       fixed: "left",
  //       width: 130,

  //       render: (text) => <p style={styleTable.hText}>{text}</p>,
  //     },
  //   ];
  //   for (let i = 0; i < detail.length; i++) {
  //     const element = {
  //       title: () => (
  //         <Tooltip placement="topLeft" title={detail[i].paramCode}>
  //           {" "}
  //           <p style={styleTable.text}>{`${
  //             detail[i].paramDescription
  //           } (${detail[i].paramUnit.replace("deg", "°")})`}</p>
  //         </Tooltip>
  //       ),
  //       dataIndex: `${detail[i].paramCode}`,
  //       key: `${detail[i].paramCode}`,
  //       render: (text) => <p>{text}</p>,
  //     };
  //     col.push(element);
  //   }
  //   setColumns([...col]);
  // };
  // const setRowsDetail = () => {
  //   if (detail.length > 0) {
  //     const row = [
  //       {
  //         item: "Actual",
  //         name: "actual",
  //       },
  //       {
  //         item: "Nearest",
  //         name: "nearest",
  //       },
  //       {
  //         item: "Diff",
  //         name: "diff",
  //       },
  //       {
  //         item: "%Diff",
  //         name: "diffPercent",
  //       },
  //       {
  //         item: "Weight (%)",
  //         name: "weight",
  //       },
  //     ];
  //     for (let i = 0; i < row.length; i++) {
  //       if (detail.length > 0) {
  //         for (let o = 0; o < detail.length; o++) {
  //           row[i][`${detail[o].paramCode}`] = detail[o][`${row[i].name}`];
  //         }
  //       }
  //     }
  //     setDetailData([...row]);
  //   }
  //   setIsLoadingDetail(false);
  // };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }
  function formatDateWithTime(isoDateString) {
    // Create a new Date object from the string
    let date = new Date(isoDateString);

    // Define month names
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract components of the date
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Pad minutes with leading zeros if necessary
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    // Combine the parts into a formatted string
    return `${monthNames[monthIndex]} ${day}, ${year} ${hours}:${minutes}`;
  }

  function replaceCharInString(string) {
    const search = '_';
    const searchRegExp = new RegExp(search, 'g');
    const replaceWith = ' ';

    const result = string.replace(searchRegExp, replaceWith)

    return result;
  }
  
  return (
    <>
      <MenuComponent {...props}></MenuComponent>
      <section className="eq_container clearfix">
        <div className="eq_back clearfix">
          <Link to="/" onClick={cleardata} className="eq_back_link">
            Home
          </Link>

          <span className="eq_breadcum eq_breadcum_tar">Equipment Status</span>
        </div>
        <div className="eq_top_img" data-parallax='{"x": -180}'>
          <img src={EquipPic} className="img_equip_pic" />
        </div>
        <div className="eq_top_sect clearfix">
          <div className="eq_content clearfix">
            <div className="eq_sentence">
              <div className="eq_sentence_title">
                <br />
                <h1 className="txt_equip_title">Equipment Status</h1>
              </div>
              <div className="eq_sentence_cont">
                Machine learning is a branch of artificial intelligence (AI) and
                computer science which focuses on the use of data and
                algorithms.
              </div>
            </div>
          </div>
          <div className="eq_sum_issue clearfix">
            {/* <div className="eq_label clearfix">Issue summary</div> */}
            <div className="eq_summary_sec clearfix">
              {/*bar*/}
              <div className="eq_sum_bar clearfix">
                <div className="eq_sum_chart clearfix">
                  {reduxruntme ? (
                     reduxruntme.filter((e) => e.plantCode === equipment.plantCode)
                     .map((obj, index) => (
                    <>
                      <div
                        className={`ml_chart_nor h${Math.round((obj.normal * 100) / 9)

                          } n${obj.normal}`}></div>
                      <div
                        className={`ml_chart_warn h${Math.round((obj.warning * 100) / 9)
                          } n${obj.warning}`}></div>
                      <div
                       className={`ml_chart_alert h${Math.round((obj.alert * 100) /9)
                      
                       } n${obj.alert}`}></div>

                      <div
                      className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / 9)) - Math.round((obj.warning * 100) / 9) - Math.round((obj.alert * 100) / 9)
                        } n${9-obj.normal-obj.warning-obj.alert}`}></div>
                      {/*<div*/}
                      {/*  className={`ml_chart_off h${Math.round((equipmentOverview.grey * 100) / equipmentOverview.total)*/}
                      
                      {/*    } n${equipmentOverview.grey}`}></div>*/}


                    </>
                      ))
                  ) : (
                  <> </>
                    
                  )}
                  <span className="text-span"> Runtime </span>
                </div>
              </div>

              <div className="eq_sum_bar clearfix">
                <div className="eq_sum_chart clearfix">
                  {showrEq ? (
                    //  reduxalert.filter((e) => e.plantCode === equipment.plantCode)
                    //  .map((obj, index) => (
                    <>
                      {/*<div*/}
                      {/*  className={`ml_chart_nor h${Math.round((equipmentOverview.green * 100) / equipmentOverview.total)*/}
                      
                      {/*    } n${equipmentOverview.green}`}></div>*/}<div
                        className={`ml_chart_nor h${(Math.round((showrEq.normal * 100) / 9)) 

                        } n${showrEq.normal}`}></div>
                      <div
                        className={`ml_chart_warn h${Math.round((showrEq.warning * 100) / 9)
                      
                         } n${showrEq.warning}`}></div>
                      <div
                        className={`ml_chart_alert h${Math.round((showrEq.alert * 100) / 9)

                          } n${showrEq.alert}`}></div>
                    </>
                      // ))
                  ) : (
                    <></>
                  )}
                  <sapn className="text-span"> Alert </sapn>
                </div>
              </div>

              {/*bar*/}
              
              <div className="eq_sum_data clearfix">
              {reduxruntme ? (
                     reduxruntme.filter((e) => e.plantCode === equipment.plantCode)
                     .map((obj, index) => (
                    <> 
                    <div className="eq_sum_data_title clearfix">
                      <span className="txt_h4txt_plant">{obj ? obj.plantGroup : ""} </span>
                      <br />
                      <span className="txt_equip_plant">
                        {obj ? obj.plantCode : ""}
                      </span>
                      {/* <form>
                        <div className="cate_date_opt_txt issue_summary">
                          Select Date :{" "}
                          <span className="co_wh">
                            <div className="graph_equip_search">
                              <DatePicker
                                className="graph_equip_date"
                                format={dateFormat}
                                onChange={handleDateChange}
                                onClick={() => setOpen1(!open1)}
                                defaultValue={moment().subtract(1, "day")}
                                value={
                                  date
                                    ? moment(date, dateFormat)
                                    : moment("", dateFormat)
                                }
                                open={open1}
                              />
                            </div>
                          </span>
                          <span>
                            <i
                              className="icon-calendar1 calend_ico"
                              onClick={() => setOpen1(!open1)}
                            />
                          </span>
                        </div>
                      </form> */}
                  </div>
                  <div className="eq_sum_data_cont clearfix">
                  <div className="eq_sum_data_box eq_sum_space">
                    <span className="eq_sum_ico">
                      <img src={DateIco} className="img_analytic" />
                    </span>
                    <span className="eq_sum_cont txt_h2txt_result">
                      {obj ? (
                        formatDate(today)
                      ) : (
                        <>-</>
                      )}
                    </span>
                    <span className="eq_sum_cont txt_h5txt_title">Date</span>
                  </div>
                  <div className="eq_sum_data_box eq_sum_space">
                    <span className="eq_sum_ico">
                      <img src={RuntimeIco} className="img_analytic" />
                    </span>
                    <span className="eq_sum_cont txt_h2txt_result co_run">
                      {obj ? obj.normal : "-"}
                    </span>
                    <span className="eq_sum_cont txt_h5txt_title">Normal</span>
                  </div>
                  <div className="eq_sum_data_box eq_sum_space">
                    <span className="eq_sum_ico">
                      <img src={WarningIco} className="img_analytic" />
                    </span>
                    <span className="eq_sum_cont txt_h2txt_result co_warning">
                      {obj ? obj.warning : "-"}
                    </span>
                    <span className="eq_sum_cont txt_h5txt_title">Warning</span>
                  </div>
                  <div className="eq_sum_data_box eq_sum_space">
                    <span className="eq_sum_ico">
                      <img src={AlertIco} className="img_analytic" />
                    </span>
                    <span className="eq_sum_cont txt_h2txt_result co_alert">
                      {obj ? obj.alert : "-"}
                    </span>
                    <span className="eq_sum_cont txt_h5txt_title">Alert</span>
                  </div>

                  <div className="eq_sum_data_box eq_sum_space">
                    <span className="eq_sum_ico">
                      <img src={OffIco} className="img_analytic" />
                    </span>
                    <span className="eq_sum_cont txt_h2txt_result co_off">
                      {obj ? 0 : "-"}
                    </span>
                    <span className="eq_sum_cont txt_h5txt_title">Off</span>
                  </div>
                </div>


                    </>
                      ))
                  ) : (
                  <> </>
                    
                  )}
              </div>
            </div>
          </div>
          {/* Equipment status Summart OLD */}
          {/* <div className="eq_sum_issue clearfix">
            <div className="issue_content clearfix">
              <div className="issue_l txt_h4txt_w clearfix"> EQUIPMENT STATUS SUMMARY </div>
              <div className="issue_date_r clearfix">
                <i className="icon-file-text" />{" "}

                <Link to="/issue_summary">View all</Link>
              </div>
            </div>
            <div className="issue_table clearfix"> */}
          {/* Table Title */}
          {/* <div className="issue_th th_row clearfix">
                <div className="issue_th txt_th1 clearfix">
                  <div className="issue_td td_date clearfix">Issue Id</div>
                  <div className="issue_td td_date clearfix">Date</div>

                  <div className="issue_td td_plant clearfix">plant</div>
                  <div className="issue_td td_eqip clearfix">Equipment</div>

                  <div className="issue_td td_btn clearfix">Alert State</div>
                  <div className="issue_td td_btn clearfix">Runtime</div>
                  <div className="issue_td td_btn clearfix">Case Status</div>
                </div>
              </div> */}
          {/* Table Title */}
          {/* Table Row */}
          {/* {issuesummary ? (
                issuesummary.map((obj, index) => (

                  <div className="issue_th th_brow clearfix" key={obj.issue_Id}>
                    <div className="issue_th txt_td1 clearfix">
                      <div className="issue_td td_plant clearfix">
                        {obj.issue_Id}
                      </div>
                      <div className="issue_td td_date clearfix">
                        {formatDate(obj.issue_DateTime)}
                      </div>

                      <div className="issue_td td_plant clearfix">
                        {obj.plant_Code}
                      </div>
                      <div className="issue_td td_eqip clearfix">
                        {obj.equip_Name}
                      </div>

                      <div className="issue_td td_btn clearfix">
                        <div className="tooltip">
                          <img
                            src={getDataAlert(obj.issue_Level).image}
                            className="btn_status"
                            alt=""
                          />
                          <span className="tooltiptext">
                            {getDataAlert(obj.issue_Level).name}
                          </span>
                        </div>
                      </div>
                      <div className="issue_td td_btn tooltip clearfix">
                        <img
                          src={getDataRuntime(obj.runTime_Level).image}
                          className="btn_status"
                          alt="s"
                        />
                        <span className="tooltiptext">
                          {getDataRuntime(obj.runTime_Level).name}
                        </span>
                      </div>
                      <div
                        className="issue_td td_btn tooltip clearfix"
                        onClick={() => handleOpenCase(obj)}>
                        <img src={statusimg(obj.issue_Status)} className="btn_a btn_status" alt="" />
                        <span className="tooltiptext">{statustotext(obj.issue_Status)}</span>
                      </div>

                    </div>
                  </div>
                ))
              ) : (
                <></>
              )} */}
          {/* Table Row */}
          {/* </div>
          </div> */}
          {/* Equipment status Summart OLD */}
          <div className="eq_sum_issue clearfix">
            <div className="issue_content clearfix">
              <div className="issue_l txt_h4txt_w clearfix"> EQUIPMENT STATUS SUMMARY </div>
              <div className="issue_date_r clearfix">
                <i className="icon-file-text" />{" "}

                <Link to="/issue_summary">View all</Link>
              </div>
            </div>
            <div className="issue_table clearfix">
              {/* Table Title */}
              <div className="issue_th th_row clearfix">
                <div className="issue_th txt_th1 clearfix">
                  <div className="issue_td td_eqip clearfix">Equipment</div>
                  <div className="issue_td td_btn clearfix">Runtime</div>
                  <div className="issue_td td_btn clearfix">Alert State</div>
                  <div className="issue_td_opc td_btn clearfix">OPEN CASE</div>
                  {/* <div classname="issue_td_pty td_btn clearfix">Priority</div> */}
                  <div className="issue_td td_btn clearfix">Case Status</div>
                  <div className="issue_td td_eqip clearfix">ASSIGNED TO</div>
                </div>
              </div>
              {/* Table Title */}
              {/* Table Row */}
              {issuesummary ? (
                issuesummary.map((obj, index) => (

                  <div className="issue_th th_brow clearfix" key={obj.issue_Id}>
                    <div className="issue_th txt_td1 clearfix">
                      <div className="issue_td td_eqip clearfix">
                        {obj.equip_Name}
                      </div>

                      <div className="issue_td td_btn tooltip clearfix">
                        <img
                            src={getDataRuntime(obj.runtime).image}
                            className="btn_status"
                            alt="s"
                        />
                        <span className="tooltiptext">
                          {getDataRuntime(obj.runtime).name}
                        </span>
                      </div>

                      <div className="issue_td td_btn clearfix">
                        <div className="tooltip">
                          <img
                              src={getDataAlert(obj.alertstate).image}
                              className="btn_status"
                              alt=""
                          />
                          <span className="tooltiptext">
                            {getDataAlert(obj.alertstate).name}
                          </span>
                        </div>
                      </div>
                      <div className="issue_td_opc td_btn clearfix">
                        {obj.opencase}
                      </div>

                      {/* <div className="issue_td_pty td_btn clearfix">
                        <div className="tooltip">
                          <img
                              src={getDataPriorty(obj.issuePriority).image}
                              className="btn_status"
                              alt=""
                          />
                          <span className="tooltiptext">
                            {getDataPriorty(obj.issuePriority).name}
                          </span>
                        </div>
                      </div> */}

                      <div
                          className="issue_td td_btn tooltip clearfix"
                          onClick={() => showModal(obj.issue_Id, index)}>
                        <img src={statusimg(obj.casestatus)} className="btn_a btn_status" alt=""/>
                        <span className="tooltiptext">{statustotext(obj.casestatus)}</span>
                      </div>
                      
                      <div className="issue_td td_eqip clearfix">
                        {obj.assignedTo}
                      </div>

                    </div>
                  </div>
                ))
              ) : (
                  <></>
              )}
              {/* Table Row */}
            </div>
          </div>
          {/* Equipment status Summart NEW */}
          <Modal title="EQUIPMENT STATUS SUMMARY" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>]} 
            width={1000} style={{
          top: 20,
        }}> 
          <div className="eq_sum_issue clearfix">
            <div className="issue_content clearfix">
              <div className="issue_l txt_h4txt_w clearfix"> EQUIPMENT STATUS SUMMARY </div>
              <div className="issue_date_r clearfix">
                <i className="icon-file-text" />{" "}

                <Link to="/issue_summary">View all</Link>
              </div>
            </div>
            <div className="issue_table clearfix">
              {/* Table Title */}
              <div className="issue_th th_row clearfix">
                <div className="issue_th txt_th1 clearfix">
                <div className="issue_td td_date clearfix">Issue Id</div>
                  <div className="issue_td td_date clearfix">Date</div>
                  <div className="issue_td td_eqip clearfix">Equipment</div>
                  <div className="issue_td td_btn clearfix">Runtime</div>
                  <div className="issue_td td_btn clearfix">Alert State</div>
                  {/* <div classname="issue_td_pty td_btn clearfix">Priority</div> */}
                  <div className="issue_td td_btn clearfix">Case Status</div>
                </div>
              </div>
              {/* Table Title */}
              {/* Table Row */}
              {issuesummary2 ? (
                issuesummary2.map((obj, index) => (

                  <div className="issue_th th_brow clearfix" key={obj.issue_Id}>
                    <div className="issue_th txt_td1 clearfix">
                    <div className="issue_td td_plant clearfix">
                        {obj.issue_Id}
                      </div>
                      <div className="issue_td td_date clearfix">
                        {formatDate(obj.issue_DateTime)}
                      </div>
                      <div className="issue_td td_eqip clearfix">
                        {obj.equip_Name}
                      </div>

                      <div className="issue_td td_btn tooltip clearfix">
                        <img
                          src={getDataRuntime(obj.runtime).image}
                          className="btn_status"
                          alt="s"
                        />
                        <span className="tooltiptext">
                          {getDataRuntime(obj.runtime).name}
                        </span>
                      </div>

                      <div className="issue_td td_btn clearfix">
                        <div className="tooltip">
                          <img
                            src={getDataAlert(obj.alertstate).image}
                            className="btn_status"
                            alt=""
                          />
                          <span className="tooltiptext">
                            {getDataAlert(obj.alertstate).name}
                          </span>
                        </div>
                      </div>

                      {/* <div className="issue_td_pty td_btn clearfix">
                        <div className="tooltip">
                          <img
                              src={getDataPriorty(obj.issuePriority).image}
                              className="btn_status"
                              alt=""
                          />
                          <span className="tooltiptext">
                            {getDataPriorty(obj.issuePriority).name}
                          </span>
                        </div>
                      </div> */}

                      <div
                        className="issue_td td_btn tooltip clearfix"
                        onClick={() => handleOpenCase(obj)}>
                        <img src={statusimg2(obj.casestatus)} className="btn_a btn_status" alt="" />
                        <span className="tooltiptext">{statustotext(obj.casestatus)}</span>
                      </div>


                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {/* Table Row */}
            </div>
          </div>
          </Modal>
          {/* Equipment status Summart NEW */}


          <div className="eq_top_bg clearfix" />
        </div>
        {/* </section> */}
        {/* Select Categories */}
        {/* Data */}
        {/* <section className="cate_container clearfix">
        <div className="eq_categories_sect clearfix">
          {/* {healthScore && healthScore.length !== 0 ? (
            healthScore.map((item, index) =>
              index === selectedCategories ? (
                <div className="cate_hc_col" key={item.id}>
                  <div className="cate_hc_top">
                    <div className="cate_hc_title">
                      <span className="txt_h3txt_hs">
                        health
                        <br />
                        score
                      </span>
                    </div>
                    <div className="cate_hc_chart">
                      <HealthScoreGauge
                        healthScore={item}
                        textColor={"rgb(0,0,0)"}></HealthScoreGauge>
                    </div>
                  </div>
                  {/* <div className="cate_hc_bot">
                    <div className="cate_hc_bot_cont">
                      <span className="txt_hs_title">Equipment</span>
                      <br />
                      <span className="txt_hs_subject">
                        {item.equipmentName ? replaceCharInString(item.equipmentName) : "-"}
                      </span>
                    </div>
                    <div className="cate_hc_bot_cont">
                      <span className="txt_hs_title">Sensors</span>
                      <br />
                      <span className="txt_hs_subject">{item.totalSensor}</span>
                    </div>
                  </div> */}
        {/* <NavLink
                    to={`/equipment_detail/${equipment.plantCode}?equipId=${item.equipId}`}>
                    <div className="cate_hc_bot_btn">
                      <img src={SearchIco} className="img_case_btn" />
                    </div>
                  </NavLink> */}
        {/* </div>
              ) : (
                <></>
              )
            )
          ) : ( */}
        <>
          {/* <div className="cate_hc_col">
                <div className="cate_hc_top">
                  <div className="cate_hc_title">
                    <span className="txt_h3txt_hs">
                      health
                      <br />
                      score
                    </span>
                  </div>
                  <div className="cate_hc_chart"></div>
                </div>
                <div className="cate_hc_bot">
                  <div className="cate_hc_bot_cont">
                    <span className="txt_hs_title">Equipment</span>
                    <br />
                    <span className="txt_hs_subject">-</span>
                  </div>
                  <div className="cate_hc_bot_cont">
                    <span className="txt_hs_title">Sensors</span>
                    <br />
                    <span className="txt_hs_subject">-</span>
                  </div>
                </div>
                <Link to={`/equipment_detail/${equipment.plantCode}`}>
                  <div className="cate_hc_bot_btn">
                    <img src={SearchIco} className="img_case_btn" />
                  </div>
                </Link>
              </div> */}
        </>
        {/* )}  */}
        
        {/*.................. NEW PAGE ................*/}
        <>
          <section className="data_container eq_b32 clearfix">
          <Spin spinning={spinning} tip="Loading..." size="large" className="contentStyle"  fullscreen>
            {/* <div className="ad_subject_title clearfix">
                <div className="eq_sentence">
                  <h2 className="txt_h3txt_head">Detail Of System</h2>
                </div>
              </div> */}
            <div className="eq_details_container clearfix">
              <div className="ad_container_sect clearfix">
                {/* Details */}
                <div className="ad_container_l clearfix">
                  {/* <div className="ad_hc_tools clearfix">
                      <div className="ad_hc_title">Health Score</div>
                      <div className="ad_hc_chart"> */}
                  {/* {equipmentDetail ? (
                          <HealthScoreGauge
                            healthScore={equipmentDetail[0]}
                            textColor={"rgb(255,255,255)"}></HealthScoreGauge>
                        ) : (
                          <></>
                        )} */}

                  {/* <img src={HalfChart2} className="img_adhs_chart" /> */}
                  {/* </div>
                    </div> */}
                  <div className="ad_hc_tools clearfix">
                    <div className="ad_hc_title alig_cen">Sensors Detection</div>
                    <div className="ad_hc_navlist clearfix">
                        {sensorv2 ? (
                            <Menu
                               multiple
                              activeBarBorderWidth="1"
                             // onSelect={onOpen} 
                               openKeys={stateOpenKeys}
                               onOpenChange={onOpen}
                               selectedKeys={selectedSensorsData}
                               // onBlur={onCloseMenu} // Assuming you want to reset on menu blur
                                // selectedKeys="null"
                              // onDeselect={closekey}
                              onClick={onClick}
                              className=".ant-menu-vertical-right .ant-menu-item::after"
                                  style={{
                                    color:"white",
                                    background: 'transparent',
                                    // border:"2px solid #26638D",
                                    itemBg:"#0C2A3D",
                                    width: 290,
                                    colorItemBgHover: "#",
                                    colorItemBgSelected: "#...",
                                    colorItemTextSelected: "#...",
                            }}
                             // defaultSelectedKeys={['19']}
                            // defaultOpenKeys={['1']}
                            mode="inline"
                           items={sensorv2}
                              // {...sensorv2.map(item => (
                              //   <Menu.Item key={item.key}>
                              //     <Tooltip title={item.tooltip || item.label}>
                              //       <span>{item.label}</span>
                              //     </Tooltip>
                              //   </Menu.Item>
                              // ))}  
                            />      
                                ) : (
          
                                    <> 
                                    {success}  
                                    </>
                                )
                            }
                    </div>
                  </div>
                </div>
                <div className="ad_container_r clearfix">
                  <div className="ad_hc_header clearfix">
                    <div className="ad_hc_header_title">
                      <span className="txt_h4txt_plant">Plant</span>
                      <br/>
                      {
                        showplant == 1 ? 
                        (
                              <span className="txt_equip_plant">

                              {equipmentDetail ? equipmentDetail[0].plantCode : ""}
                            </span>
                        )
                        :
                          <span className="txt_equip_plant">
                              {""}
                          </span>
                        }
                     
                    </div>
                    <div className="ad_hc_header_equip">
                      <span className="txt_h4txt_plant"> Equipment</span>
                      <br/>
                      {
                        showplant == 1 ?
                        (
                          <span className="txt_equip_acc">
                          {equipmentDetail ? equipmentDetail[0].equipNameDisplay : ""}
                            {/* {equipmentDetail ? replaceCharInString(equipmentDetail[0].equipmentName) : ""} */}
                            {/* {equipmentDetail[0].paramDescription} : {equipmentDetail[0].paramCode} */}
                          </span>
                        )
                        :
                        (
                          <span className="txt_equip_acc">
                             {""}
                          </span>
                        )
                      }
                      
                    </div>
                    <div className="ad_hc_header_equip ac_sensors">
                      <span className="txt_h4txt_plant"></span>
                      <br/>
                      <span className="txt_equip_acc">
                        {" "}
                        {/* {equipmentDetail ? equipmentDetail[0].totalSensor : ""} */}
                      </span>
                    </div>
                    <span>
                      <img src={EquipIco} className="ad_hc_equip_img"/>
                    </span>
                  </div>
                </div>
                <div className="ad_calendar_r clearfix">
                  <form>
                  <div className="cate_date_opt_txt"> Range:
                         <Select className="box-select_day textcolor"
                                 defaultValue=" 1 Day"
                                 style={{color: "#ffffff"}}
                                 options={[
                                   {
                                     value: '1',
                                     label: ' 1 Day',
                                   },
                                   {
                                    value: '7',
                                    label: ' 7 Day',
                                  },
                                   {
                                     value: '14',
                                     label: ' 14 Day'
                                   },
                                   {
                                     value: '30',
                                     label: ' 30 Day'
                                   },
                                   {
                                     value: '60',
                                     label: ' 60 Day'
                                   }
                                 ]}
                                 onChange={handleChange}
                         />
                        
                    {/* <div className="cate_date_opt_txt"> */}
                      Select Date :{" "}
                      <span className="co_wh">
                        <div className="graph_equip_search">
                          <DatePicker
                              className="graph_equip_date"
                              format={dateFormat}
                              onChange={handleDateChange}
                              onClick={() => setOpen2(!open2)}
                              defaultValue={moment().subtract(1, "day")}
                              value={
                                date
                                    ? moment(date, dateFormat)
                                    : moment("", dateFormat)
                              }
                              open={open2}
                          />
                        </div>
                        <span>
                        <i
                            className="icon-calendar1 calend_ico"
                            onClick={() => setOpen2(!open2)}
                        />
                      </span>
                    
                      </span>
                         <Tooltip title="search">
                          <Button style={{margin: "20px", 
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "9px"}} 
                                          icon={<ReloadOutlined />} 
                                          onClick={handleDateChange1}
                                          />
                        </Tooltip> 
                    {/* </div> */}
                  </div>
                  </form>
                </div>
                {/* <div className="ad_loadtype clearfix">
                    <div className="ad_loadtype_head clearfix">
                      <span>LOAD TYPE</span>
                    </div>
                    {loadType ? (
                      loadType.map((item, index) => (
                        <div className="ad_loadtype_box clearfix">
                          <span className="ad_loadtype_txt txt_l txt_h5lt_num">
                            {item.loadtype}
                          </span>
                          <span className="ad_loadtype_txt txt_h5lt_result">
                            {item.weight}
                            <span className="txt_h5lt_percent">%</span>
                          </span>
                          <span className="ad_loadtype_txt txt_h5lt_mw">
                            {item.minValue} - {item.maxValue} MW
                          </span>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div> */}
              <div class="scrollgraph custom-scrollbar">
                <div className="ad_sens_chart_sum_anoly clearfix">
                  <span className="ad_hc_title_le left_le"> SYSTEM ANOMALY SCORE </span>
                  <div className="pla_s_charts clearfix">
                 
                    {anomalyGraphSum?.length > 0 ?(
                         <AnomalyChartGraphSum  
                         anomalyData={anomalyGraphSum.sort((a, b) => a.date_time - b.date_time)}
                         date={datesensor}
                         />
                    
                    ) :  (
                      <h1>Please select equipment</h1>
                  )}
               
                    
                 
        
                    </div>
                </div>
                <div className="ad_sens_chart_sum_anoly clearfix">
                  <span className="ad_hc_title_le left_le">  </span>
                  <div className="pla_s_charts_tank clearfix">
                   
                   {Graphtang?.length > 0 ?(
                         <BarChart
                         data={Graphtang}
                        //  anomalyData={anomalyGraphSum.sort((a, b) => a.date_time - b.date_time)}
                        //  date={datesensor}
                         />
                    
                    ) :  (
                      <h1>Please select equipment</h1>
                  )}
                    
                 
        
                    </div>
                </div>
                <div className="ad_sens_chart_sum clearfix">
                  {/* Chart Summary */}
                  {selectedSensorsData?.length > 0 && sensor?.length > 0 ? (
                      selectedSensorsData.map((item, index) => {
                        return (
                            <div className="ad_sens_chart_sect clearfix">
                              <div className="data_content_title clearfix">
                                <div className="ad_sens_chart_title">
                              <span className="txt_h3txt_chart_title">
                                {getPlantCode(item, sensor)}
                              </span>
                                </div>
                                <div className="ad_sens_chart_num">
                              {/* <span className="cate_detect_opt_txt">
                                Sensors Detection1
                              </span> */}
                                  <span className="co_warn_sta">
                                {sensor != null &&
                                sensor != undefined &&
                                sensor[item] ? (
                                    <>{sensor[item].score}</>
                                ) : (
                                    <></>
                                )}
                              </span>
                                </div>
                              </div>
                              {/* {
                        JSON.stringify(anomaly.filter(e => (e.paramId === item.data["paraM_ID"])).map((dataG) =>{
                              return dataG.dt
                        }))
                      } */}
                            
                              {
                                
                              <div className="pla_s_charts clearfix">
                                {sensornew && sensornew.length > 0 ? (
                                    anomalyGraph && Array.isArray(anomalyGraph) ? (
                                        <AnomalyChartGraph
                                            anomalyData={anomalyGraph.filter(e => (e.paramCode === item)).sort((a, b) => a.date_time - b.date_time)}
                                           
                                        />
                                    ) : (
                                        <Spin spinning={spinningGraph} tip="Loading..." size="large" className="contentStyle" />
                                    )
                                ) : (
                                    <Spin spinning={spinningGraph} tip="Loading..." size="large" className="contentStyle" />
                                )}
                                {/* <Spin tip="loading" size="large" spinning={isLoadingGraph}></Spin> */}
                            </div>
                                // (() => {
                                //   let elements = [];
                                //   for (let element in item.data) {
                                //     if (element != "paraM_ID") {
                                //       elements.push(item.data[element]);
                                //     }
                                //   }
                                //   return elements;
                                // })().map((img, index) => (
                                //   base64ToImage(img).src !=="data:image/png;base64,null"? (
                                //    <div className="ad_sens_chart_col clearfix">
                                //     <div className="ad_sens_chart_result">
                                //       <span className="txt_h5ad_count">
                                //         {index + 1}
                                //       </span>
                                //       <div className="ad_sens_chart_img">
                                //         {/* <img
                                //           src={base64ToImage(img).src}
                                //           className="chart_cap_img"
                                //         /> */}
                                //         <ModalImage
                                //         small={base64ToImage(img).src}
                                //         large={base64ToImage(img).src}
                                //         alt={base64ToImage(img).src}
                                //       />
                                //       </div>
                                //     </div>
                                //   </div>
                                //   ):(<></>)


                                // ))
                              }
                            </div>
                        );
                      })
                  ) : (
                      <> </>
                  )}
                  <Spin tip="" size="large" spinning={isLoading}></Spin>

                  {/* Chart Summary */}
                </div>
              </div>
            </div>
            </div>
            </Spin> 
          </section>
          {/* Data */}
          {/* BG */} 
          <div
              className="ml_sect_bg ml_bg_fixed"
              style={{backgroundImage: `url(${BgImage})`}}
          />
          {/* BG */}
          {/*Modal Case Issue */}
          <div
              className="modal fade"
              id="case_issue"
              data-keyboard="false"
              data-backdrop="false">
            {/*?php include("case_issue.php"); ?*/}
          </div>
          {/*Modal Case Issue */}
          <FooterComponent></FooterComponent>  
      
        </>


        {/*............................. page old ................................*/}
        {/* <div className="cate_navi_col">
            <div className="eq_cate_title">Categories</div>
            <div className="eq_cate_sort">
              <div
                className={`eq_cate_sort_btn score ${selectedSort === 0 ? "selected" : ""
                  }`}
                onClick={handleSortByScoreClick}>
                Score
              </div>
              <div
                className={`eq_cate_sort_btn az ${selectedSort === 0 ? "" : "selected"
                  }`}
                onClick={handleSortByAZClick}>
                A - Z
              </div>
            </div>
            <div className="eq_plant_group clearfix">
              {healthScore ? (
                healthScore.map((item, index) => {
                  if (index === selectedCategories) {
                    selectedIndex = index;
                    selectedEquipId = item.equipId;
                  }

                  return (
                    <div
                      className={`eq_cate_navi_col eq_plant_warn_col eq_plant_warn_col${item.issueLevel} ${index === selectedCategories ? "selected" : ""
                        }`}
                      key={item.id}
                      onClick={() =>
                        handleSelectedCategories(index, item.equipId)
                      }>
                      <div className="eq_plant_sub">
                        <div className="txt_equip_number">
                          {item.scoreOutliner}
                        </div>
                        <span className="txt_equip_name wh_col_txt">
                          {item.equipmentName ? replaceCharInString(item.equipmentName) : "-"}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div> */}
        {/* <div className="cate_date_opt">
            <form>
              <div className="cate_date_opt_txt">
                Select Date :{" "}
                <span className="co_wh">
                  <div className="graph_equip_search">
                    <DatePicker
                      className="graph_equip_date"
                      format={dateFormat}
                      onChange={handleDateChange}
                      onClick={() => setOpen2(!open2)}
                      defaultValue={moment().subtract(1, "day")}
                      value={
                        date ? moment(date, dateFormat) : moment("", dateFormat)
                      }
                      open={open2}
                    />
                  </div>
                </span>
                <span>
                  <i
                    className="icon-calendar1 calend_ico"
                    onClick={() => setOpen2(!open2)}
                  />
                </span>
              </div>
            </form>
          </div> */}
        {/* <div className="plant_status_col">
            <div className="pla_s_title clearfix">
              <span className="txt_h3txt_plan">
                Health
                <br />
                Score
              </span>
            </div>
            <div className="pla_s_charts clearfix">
              {anomaly?.length > 0 ? (
                isLoadingAnomaly ? null : (
                  <AnomalyChart anomalyData={anomaly} date={date} />
                )
              ) : isLoadingAnomaly ? null : (
                <h1>NO DATA</h1>
              )}
              <Spin tip="" size="large" spinning={isLoadingAnomaly}></Spin>
            </div>
          </div> */}
        {/* <div className="plant_status_col">
            <div className="pla_s_title clearfix">
              <span className="txt_h3txt_plan">
              KNN
                <br />
                Distance
              </span>
            </div>
            <div className="pla_s_charts clearfix">
              {plantStatus?.length > 0 ? (
                isLoadingPlantStatus ? null : (
                  <PlantStatus
                    plantStatusData={plantStatus}
                    date={date}
                    clickFunction={PlantStatusClickHandler}
                  />
                )
              ) : isLoadingPlantStatus ? null : (
                <h1>NO DATA</h1>
              )}

              <Spin
                tip="Loading"
                size="large"
                spinning={isLoadingPlantStatus}
                spin></Spin>
            </div>
          </div> */}
        {/* </div> */}
        {/* </section> */}
        {/* <section className="cate_container clearfix">
        <div className="eq_categories_sect clearfix">
          <div className="data_content_title clearfix">
            <div className="eq_sentence_l">
              <span className="txt_h3txt_plan">KNN-Signal Contributions</span>
             
            </div>
            <div className="eq_sentence_r">
              <div className="eq_b8">
                Data Point :
                <span className="co_wh">
                  {dataFromPlantStatus
                    ? " " +
                      formatDateWithTime(dataFromPlantStatus.recordDatetime)
                    : " -"}
                </span>
              </div>
              <div>
                Distance :
                <span className="co_result">
                  {dataFromPlantStatus
                    ? " " + dataFromPlantStatus.knnDistance + " "
                    : " - "}
                </span>
                | Moving Average :
                <span className="co_result">
                  {dataFromPlantStatus
                    ? " " + dataFromPlantStatus.mavgKnnDistance + " "
                    : " - "}
                </span>
                | Threshold Distance:
                <span className="co_result">
                  {dataFromPlantStatus
                    ? " " + dataFromPlantStatus.knnThreshold1 + " "
                    : " - "}
                </span>
              </div>
            </div>
          </div>
          <div className="data_content clearfix">
            {detailData?.length > 0 ? (
              isLoadingDetail ? null : (
                <Table
                  scroll={{ x: 3000, y: 500 }}
                  columns={columns}
                  dataSource={detailData}></Table>
              )
            ) : isLoadingDetail ? null : (
              <h1>NO DATA</h1>
            )}
            <Spin tip="" size="large" spinning={isLoadingDetail}></Spin>
          </div>
        </div>
      </section> */}
        {/* <div
        className="ml_sect_bg ml_bg_fixed"
        style={{ backgroundImage: `url(${BgImage})` }}
      /> */}
        {/* BG */}
        {/*Modal Case Issue */}
        {/* <div
        className="modal fade"
        id="case_issue"
        data-keyboard="false"
        data-backdrop="false">

      </div> */}
        {/*Modal Case Issue */}
        {/* <FooterComponent></FooterComponent> */}
        <CaseIssue
        visible={visible}
        onHide={() => setVisible(false)}
        caseData={caseData} />
      </section> 
    </>
  );
};
export default EquipmentNew;
